import React from 'react'
import { Link } from 'react-router-dom'
import { Config } from '../../config'

const Footer = () => {
    return (
        <footer className=''>
                <div className='container-box bg-footer'>
                    <div className="row justify-content-center">
                        <div className="col-md-4 mt-5">
                            <h4 className='text-white'>SERVICES</h4>
                            <div className='mt-4 footer-links' style={{paddingTop:"8px"}}>
                                <Link to={`${Config.appUrl}/start-ups`}> <i className='bi bi-chevron-right me-2 '></i>New Restaurant / Start Ups</Link>
                                <Link to={`${Config.appUrl}/menu-development-branding`}> <i className='bi bi-chevron-right me-2 '></i>  Menu Development & Branding</Link>
                                <Link to={`${Config.appUrl}/website-development`}> <i className='bi bi-chevron-right me-2 '></i>  Digital Development</Link>
                                <Link to={`${Config.appUrl}/performance`}> <i className='bi bi-chevron-right me-2 '></i> Restaurant Operations</Link>
                                <Link to={`${Config.appUrl}/about`}> <i className='bi bi-chevron-right me-2 '></i> Company</Link>
                            </div>
                        </div>
                        <div className="col-md-4 mt-5">
                            <h4 className='text-white'>COMPANY INFO</h4>
                            <div className='mt-4 footer-links' style={{paddingTop:"8px"}}>
                                <Link to={`${Config.appUrl}/about`} > <i className='bi bi-chevron-right me-2 '></i>About us</Link>
                               
                                <Link to={`${Config.appUrl}/blog`}> <i className='bi bi-chevron-right me-2 '></i>  Blog</Link>
                                <Link to={`${Config.appUrl}/testimonial`}> <i className='bi bi-chevron-right me-2 '></i> Testimonial</Link>
                                <Link to={`${Config.appUrl}/terms`}> <i className='bi bi-chevron-right me-2 '></i> Terms & Conditions</Link>
                                <Link to={`${Config.appUrl}/privacy-policy`}> <i className='bi bi-chevron-right me-3 '></i>Privacy Policy</Link>
                                <Link to={`${Config.appUrl}/contact`}> <i className='bi bi-chevron-right me-2 '></i> Contact us</Link>
                            </div>
                            <div className='mt-4 footer-links' style={{paddingTop:"8px"}}>

                            </div>
                        </div>

                        <div className="col-md-4 mt-5">
                            <h4 className='text-white'>CONTACT DETAILS</h4>
                            <div className='mt-4 footer-links'>
                                <p className='text-white '>
                                    
                                    
                                        
                                    <i className="bi bi-telephone-inbound me-3" > </i>
                                    <a href="tel:+1 365-662-4183 " className='text-decoration-none d-inline paragraph pe-2  number1'>  +1 365-662-4183 </a>
                                   

                                    
                                    
                                    
                                    
                                </p>
                                

                                <a href="mailto:info@ckconsultings.com" className='d-flex'> <i className="bi bi-envelope me-3 pe-1"></i> info@ckconsultings.com</a>
                                <p className='paragraph-2 d-flex text-white' style={{ fontSize: "16.5px" }}>
                                    <i className="bi bi-geo-alt me-3 pe-2"></i>
                                    5746 Finch Ave East, Scarborough<br/> ON M1B5J9
                                </p>
                                <div className='social-links d-flex mt-4'>
                                    <a target='_blank' href="#"><i className="bi bi-facebook text-white"></i></a>
                                    <a target='_blank' href="#"><i className="bi bi-instagram text-white"></i></a>
                                    <a target='_blank' href="#"><i className="bi bi-twitter text-white"></i></a>
                                    <a target='_blank' href="#"><i className="bi bi-linkedin text-white"></i></a>
                                </div>

                            </div>
                        </div>
                    </div>
                    <div className='text-center container-box py-4  '>
                        <div className='paragraph-2 p-2 text-dark fw-bold  '>
                            Copyrights &copy; 2024 - Ck Consultings
                            Designed by
                            <a href="https://www.zithas.com/" target='_blank' className='text-decoration-none fw-bold' style={{ color: "black" }}> Zithas Technologies </a>
                        </div>
                    </div>
                </div>
           
        </footer>
    )
}

export default Footer