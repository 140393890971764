import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import MainLogo from "../../assets/Images/white-logo.png";
import { Config } from "../../config";
import canadaFlag from "../../assets/Images/canadian_flag.jpg"
import "../../styles/global.css"


const Navbar = ({ scrollToSection }) => {
  const [stickyNavbar, setStickyNavbar] = useState(false);
  const [showsidebar, setShowsidebar] = useState(false);
  window.addEventListener("scroll", () => {
    if (window.scrollY > 100) {
      setStickyNavbar(true);
    } else {
      setStickyNavbar(false);
    }
  });

  const handleLinkClick = (section) => {
    if (scrollToSection) {
      scrollToSection(section);
    }
  };
  useEffect(() => {
    // Update body overflow style based on showsidebar state
    if (showsidebar) {
      document.body.style.overflow = "hidden"; // Disable scrolling
    } else {
      document.body.style.overflow = ""; // Enable scrolling
    }
  }, [showsidebar]);
  return (
    <div>
      <div
        className={`container-box py-4 position-absolute z-3 w-100  ${stickyNavbar ? "" : ""
          }`}
      >
        {/* navbar 2 */}
        <nav class="navbar bg-body-tertiary navbar2">
          <div class="container-fluid navbar2-inn">
          <Link to={`${Config.appUrl}/`} className="text-decoration-none d-flex navbar-brand">
            
            <img
              src={MainLogo}
              alt="Ck Consultant"
              height="25"
              className="object-fit-cover me-2"
            /> <h2 className="site-branding-text text-center mb-0 fs-3">
                Consultings
              </h2></Link>
             
            <div class="d-flex navbar2-icons" role="search">
              <a className="" target='_blank' href="#">
                <i className="bi bi-twitter"></i>
              </a>
              <a className="" target='_blank' href="#">
                <i className="bi bi-facebook"></i>
              </a>
              <a className="" target='_blank' href="#">
                <i className="bi bi-instagram"></i>
              </a>
              <a className="" target='_blank' href="#">
                <i className="bi bi-linkedin"></i>
              </a>
              <a className="">
                <i className="bi bi-list" onClick={() => setShowsidebar(true)} ></i>

              </a>
            </div>

          </div>
        </nav>
        
        {/* navbar 2 end */}
        <div className="row justify-content-around navbar1 align-items-center">
          <div className="row col-4 ck-soical-icon">
            <a className="col-2" target='_blank' href="#">
              <i className="bi bi-twitter"></i>
            </a>
            <a className="col-2" target='_blank' href="#">
              <i className="bi bi-facebook"></i>
            </a>
            <a className="col-2" target='_blank' href="#">
              <i className="bi bi-instagram"></i>
            </a>
            <a className="col-2" target='_blank' href="#">
              <i className="bi bi-linkedin"></i>
            </a>
            
          </div>
          <div className="col-4 ">
            <Link to={`${Config.appUrl}/`} className="text-decoration-none">
              <div
                data-aos="fade-down"
                className="navbar-logo"
                data-aos-delay="10"
              >
               <div>
                  

                <img
                  src={MainLogo}
                  alt="Ck Consultant"
                  height="35"
                  className="object-fit-cover me-2"
                />
                
                </div>
                <div>
                <h2 className="site-branding-text  mb-0 ">
                  Consultings
                </h2>
                
                </div>
              </div>
            </Link>
          </div>
          <div className="col-4 pt-1">
            <div className="row justify-content-center">

              <a className="d-flex gap-1 col-6 bord justify-content-end border-end border-white text-decoration-none text-white align-items-center" style={{ fontSize: "15px"  }}>
              <img src={canadaFlag}  alt="" width="28" height='15' style={{marginRight:"4px"}}/> 

                
               
               
                
                <a href="tel:+1 365-662-4183" className=" text-white text-decoration-none number" >+1 365-662-4183</a>
               
                
                

                </a>
              
              <a className="d-flex gap-1 col-6 bord text-center text-decoration-none text-white align-items-center" style={{ fontSize: "15px" }}>
                
                <a href="mailto:info@ckconsultings.com" style={{ textDecoration: "none", color: "white" }}>info@ckconsultings.com</a>
              </a>
            </div>

          
          </div>
        </div>

        {/* sidebar start */}
        <div className={showsidebar ? "sidebar open" : "sidebar"}>
          <div className="d-flex justify-content-between" style={{ background: "#663A8D" }}>

            {/* logo */}
            <Link to={`${Config.appUrl}/`} className="text-decoration-none">
            <div class="d-flex navbar-brand py-4 " style={{paddingLeft:"9px",}}>
            
              <img
              src={MainLogo}
              alt="Ck Consultant"
              height="25"
              className="object-fit-cover me-2"
            /> <h2 className="site-branding-text text-center mb-0 fs-3">
                Consultings
              </h2>
              

            </div>
            </Link>
            <div className="d-flex justify-content-end ">
              <button
                className="btn btn primary"
                onClick={() => setShowsidebar(false)}
              >
                <h2 className="text-white">
                  <i class="bi bi-x"></i>
                </h2>
              </button>
            </div>
          </div>
          <ul>
            <li className="main-li">
              <div
                className="accordion accordion-sidebar"
                id="new-restaurant-accordion"
              >
                <div className="accordion-item accordion-item2">
                  <h2 className="accordion-header" id="new-restaurant-heading">
                    <button
                      className="accordion-button accordion-button2"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#new-restaurant-collapse"
                      aria-expanded="false"
                      aria-controls="new-restaurant-collapse"
                    >
                      New Restaurant / Start Ups
                      <i className="bi bi-chevron-down"></i>
                    </button>
                  </h2>
                  <div
                    id="new-restaurant-collapse"
                    className="accordion-collapse collapse"
                    aria-labelledby="new-restaurant-heading"
                    data-bs-parent="#new-restaurant-accordion"
                  >
                    <div className="accordion-body ">
                      <ul>
                        <li onClick={() => handleLinkClick("new-development")}>
                          <Link to={`${Config.appUrl}/start-ups`}>
                            New Restaurant Development
                          </Link>
                        </li>
                        <li
                          onClick={() => handleLinkClick("concept-development")}
                        >
                          <Link to={`${Config.appUrl}/start-ups`}>
                            Concept Development Plan
                          </Link>
                        </li>
                        <li onClick={() => handleLinkClick("strategic-plan")}>
                          <Link to={`${Config.appUrl}/start-ups`}>
                            Strategic Business Plan
                          </Link>
                        </li>
                        <li
                          onClick={() => handleLinkClick("study")}
                        >
                          <Link to={`${Config.appUrl}/start-ups`}>
                            Feasibility Study
                          </Link>
                        </li>
                        <li onClick={() => handleLinkClick("marketing")}>
                          <Link to={`${Config.appUrl}/start-ups`}>
                            Marketing Plan
                          </Link>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </li>
            <li className="main-li">
              <div
                className="accordion accordion-sidebar"
                id="menu-development-accordion"
              >
                <div className="accordion-item">
                  <h2
                    className="accordion-header"
                    id="menu-development-heading"
                  >
                    <button
                      className="accordion-button accordion-button2"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#menu-development-collapse"
                      aria-expanded="false"
                      aria-controls="menu-development-collapse"
                    >
                      Menu Development & Branding
                      <i className="bi bi-chevron-down"></i>
                    </button>
                  </h2>
                  <div
                    id="menu-development-collapse"
                    className="accordion-collapse collapse"
                    aria-labelledby="menu-development-heading"
                    data-bs-parent="#menu-development-accordion"
                  >
                    <div className="accordion-body">
                      <ul>
                        <li onClick={() => handleLinkClick("menu-development")}>
                          <Link
                            to={`${Config.appUrl}/menu-development-branding`}
                          >
                            Menu Development
                          </Link>
                        </li>
                        <li onClick={() => handleLinkClick("menu-costing")}>
                          <Link
                            to={`${Config.appUrl}/menu-development-branding`}
                          >
                            Recipe And Menu Costing
                          </Link>
                        </li>
                        <li
                          onClick={() =>
                            handleLinkClick("chef-menu-development")
                          }
                        >
                          <Link
                            to={`${Config.appUrl}/menu-development-branding`}
                          >
                            Chef Inspired Menu Development
                          </Link>
                        </li>
                        <li onClick={() => handleLinkClick("menu-engineering")}>
                          <Link
                            to={`${Config.appUrl}/menu-development-branding`}
                          >
                            Menu Engineering
                          </Link>
                        </li>
                        <li onClick={() => handleLinkClick("brand-guide")}>
                          <Link
                            to={`${Config.appUrl}/menu-development-branding`}
                          >
                            Brand Guide Playbook
                          </Link>
                        </li>
                        <li onClick={() => handleLinkClick("logo-development")}>
                          <Link
                            to={`${Config.appUrl}/menu-development-branding`}
                          >
                            Logo Development
                          </Link>
                        </li>
                        <li onClick={() => handleLinkClick("graphic-design")}>
                          <Link
                            to={`${Config.appUrl}/menu-development-branding`}
                          >
                            Graphic Design
                          </Link>
                        </li>
                        <li>
                          <Link to={`${Config.appUrl}/brand`}>
                            Brand Development and Marketing
                          </Link>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </li>
            <li className="main-li">
              <div
                className="accordion accordion-sidebar"
                id="digital-development-accordion"
              >
                <div className="accordion-item">
                  <h2
                    className="accordion-header"
                    id="digital-development-heading"
                  >
                    <button
                      className="accordion-button accordion-button2"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#digital-development-collapse"
                      aria-expanded="false"
                      aria-controls="digital-development-collapse"
                    >
                      Digital Development
                      <i className="bi bi-chevron-down"></i>
                    </button>
                  </h2>
                  <div
                    id="digital-development-collapse"
                    className="accordion-collapse collapse"
                    aria-labelledby="digital-development-heading"
                    data-bs-parent="#digital-development-accordion"
                  >
                    <div className="accordion-body">
                      <ul>
                        <li>
                          <Link to={`${Config.appUrl}/website-development`}>
                            Website Development
                          </Link>
                        </li>
                        <li>
                          <Link to={`${Config.appUrl}/website-maintenance`}>
                            Website Maintenance
                          </Link>
                        </li>
                        <li>
                          <Link to={`${Config.appUrl}/social-media-marketing`}>
                            Social Media Marketing
                          </Link>
                        </li>
                        <li>
                          <Link to={`${Config.appUrl}/seo`}>
                            Restaurant SEO
                          </Link>
                        </li>
                        <li>
                          <Link to={`${Config.appUrl}/online-ordering`}>
                            Online Ordering
                          </Link>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </li>
            <li className="main-li">
              <div
                className="accordion accordion-sidebar"
                id="restaurant-operations-accordion"
              >
                <div className="accordion-item">
                  <h2
                    className="accordion-header"
                    id="restaurant-operations-heading"
                  >
                    <button
                      className="accordion-button accordion-button2"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#restaurant-operations-collapse"
                      aria-expanded="false"
                      aria-controls="restaurant-operations-collapse"
                    >
                      Restaurant Operations
                      <i className="bi bi-chevron-down"></i>
                    </button>
                  </h2>
                  <div
                    id="restaurant-operations-collapse"
                    className="accordion-collapse collapse"
                    aria-labelledby="restaurant-operations-heading"
                    data-bs-parent="#restaurant-operations-accordion"
                  >
                    <div className="accordion-body">
                      <ul>
                        <li>
                          <Link to={`${Config.appUrl}/performance`}>
                            Restaurant Performance Measurement
                          </Link>
                        </li>
                        <li>
                          <Link to={`${Config.appUrl}/menu-planning`}>
                            Menu Planning and Recipe Development
                          </Link>
                        </li>
                        <li>
                          <Link to={`${Config.appUrl}/restaurant-systems`}>
                            Restaurant Systems and Controls
                          </Link>
                        </li>
                        <li>
                          <Link to={`${Config.appUrl}/ckc-metrics`}>
                            CKC Metrics Technology
                          </Link>
                        </li>
                        <li>
                          <Link to={`${Config.appUrl}/ckc-strategic`}>
                            CKC Strategic Partners

                          </Link>
                        </li>
                        <li>
                          <Link to={`${Config.appUrl}/expert-advice`}>
                            Expert Advice
                          </Link>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </li>
            <li className="main-li">
              <div
                className="accordion accordion-sidebar"
                id="company-accordion"
              >
                <div className="accordion-item">
                  <h2 className="accordion-header" id="company-heading">
                    <button
                      className="accordion-button accordion-button2"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#company-collapse"
                      aria-expanded="false"
                      aria-controls="company-collapse"
                    >
                      Company
                      <i className="bi bi-chevron-down"></i>
                    </button>
                  </h2>
                  <div
                    id="company-collapse"
                    className="accordion-collapse collapse"
                    aria-labelledby="company-heading"
                    data-bs-parent="#company-accordion"
                  >
                    <div className="accordion-body">
                      <ul>
                        <li>
                          <Link to={`${Config.appUrl}/about`}>About Us</Link>
                        </li>
                        <li>
                          <Link to={`${Config.appUrl}/ourteam`}>Our Team</Link>
                        </li>
                        <li>
                          <Link to={`${Config.appUrl}/blog`}>Blog</Link>
                        </li>
                        <li>
                          <Link to={`${Config.appUrl}/testimonial`}>
                            Testimonial
                          </Link>
                        </li>
                        {/* <li>
                          <Link to={`${Config.appUrl}/gallery`}>Gallery</Link>
                        </li> */}
                        <li>
                          <Link to={`${Config.appUrl}/contact`}>Contact us</Link>
                        </li>
                        <li>
                          <Link to={`${Config.appUrl}/terms`}>Terms & Conditions</Link>
                        </li>
                        <li>
                          <Link to={`${Config.appUrl}/privacy-policy`}>Privacy Policy</Link>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </li>
          </ul>
          <div className="d-flex flex-column gap-3 sidebar-contact pt-5 align-items-center">

            <div className=" px-4  fw-bolder ">
              <a href="tel:+1 365-662-4183" style={{ textDecoration: "none", color: "#663A8D" }} className="d-flex align-items-center ">
              <img src={canadaFlag} className="me-1" width="30" height='18' alt="" /> +1 365-662-4183</a>
              
            </div>
            

            <div className="px-4 fw-bolder">
              <i className="bi bi-envelope-arrow-up-fill"></i><a href="mailto:info@ckconsultings.com" style={{ textDecoration: "none", color: "#663A8D" }}> info@ckconsultings.com</a>
            </div>

          </div>
          <div className="text-center sidebar-animate">
            <img src="https://media1.giphy.com/media/v1.Y2lkPTc5MGI3NjExNGs0MzlpZ3c4NW84b2tsNWp0Mjk3cG9icTZldmNzYzR2ZnlmdDE4dCZlcD12MV9pbnRlcm5hbF9naWZfYnlfaWQmY3Q9Zw/1xoqF0qfoeekf2XLyy/giphy.gif" />
          </div>
        </div>
        {/* sidebar end */}

        
        <div className="main-navigation pt-4">
          <ul>
            <li className="main-li">
              <Link to={`${Config.appUrl}/start-ups`}>
                New Restaurant / Start Ups
              </Link>
              <div className="inner-links text-start">
                <li onClick={() => handleLinkClick("new-development")}>
                  <Link to={`${Config.appUrl}/start-ups`}>
                    New Restaurant Development
                  </Link>
                </li>
                <li onClick={() => handleLinkClick("concept-development")}>
                  <Link to={`${Config.appUrl}/start-ups`}>
                    Concept Development Plan
                  </Link>
                </li>
                <li onClick={() => handleLinkClick("strategic-plan")}>
                  <Link to={`${Config.appUrl}/start-ups`}>
                    Strategic Business Plan
                  </Link>
                </li>
                <li onClick={() => handleLinkClick("study")}>
                  <Link to={`${Config.appUrl}/start-ups`}>
                    Feasibility Study
                  </Link>
                </li>
                <li onClick={() => handleLinkClick("marketing")}>
                  <Link to={`${Config.appUrl}/start-ups`}>Marketing Plan</Link>
                </li>
              </div>
            </li>
            <li>
              <Link to={`${Config.appUrl}/menu-development-branding`}>
                Menu Development & Branding
              </Link>
              <div className="inner-links text-start">
                <li onClick={() => handleLinkClick("menu-development")}>
                  <Link to={`${Config.appUrl}/menu-development-branding`}>
                    Menu Development
                  </Link>
                </li>
                <li onClick={() => handleLinkClick("menu-costing")}>
                  <Link to={`${Config.appUrl}/menu-development-branding`}>
                    Recipe And Menu Costing
                  </Link>
                </li>
                <li onClick={() => handleLinkClick("chef-menu-development")}>
                  <Link to={`${Config.appUrl}/menu-development-branding`}>
                    Chef Inspired Menu Development
                  </Link>
                </li>
                <li onClick={() => handleLinkClick("menu-engineering")}>
                  <Link to={`${Config.appUrl}/menu-development-branding`}>
                    Menu Engineering
                  </Link>
                </li>
                <li onClick={() => handleLinkClick("brand-guide")}>
                  <Link to={`${Config.appUrl}/menu-development-branding`}>
                    Brand Guide Playbook
                  </Link>
                </li>
                <li onClick={() => handleLinkClick("logo-development")}>
                  <Link to={`${Config.appUrl}/menu-development-branding`}>
                    Logo Development
                  </Link>
                </li>
                <li onClick={() => handleLinkClick("graphic-design")}>
                  <Link to={`${Config.appUrl}/menu-development-branding`}>
                    Graphic Design
                  </Link>
                </li>
                <li className="main-li">
                  <Link to={`${Config.appUrl}/brand`}>
                    Brand Development and Marketing
                  </Link>
                </li>
              </div>
            </li>
            <li>
              <Link to={`${Config.appUrl}/website-development`}>
                Digital Development
              </Link>
              <div className="inner-links text-start">
                <li>
                  <Link to={`${Config.appUrl}/website-development`}>
                    Website Development
                  </Link>
                </li>
                <li>
                  <Link to={`${Config.appUrl}/website-maintenance`}>
                    Website Maintenance
                  </Link>
                </li>
                <li>
                  <Link to={`${Config.appUrl}/social-media-marketing`}>
                    Social Media Marketing
                  </Link>
                </li>
                <li>
                  <Link to={`${Config.appUrl}/seo`}>Restaurant SEO</Link>
                </li>
                <li>
                  <Link to={`${Config.appUrl}/online-ordering`}>Online Ordering</Link>
                </li>
              </div>
            </li>
            <li className="main-li">
              <Link to={`${Config.appUrl}/performance`}>
                Restaurant Operations
              </Link>
              <div className="inner-links text-start">
                <li>
                  <Link to={`${Config.appUrl}/performance`}>
                    Restaurant Performance Measurement
                  </Link>
                </li>
                <li>
                  <Link to={`${Config.appUrl}/menu-planning`}>
                    Menu Planning and Recipe Development
                  </Link>
                </li>
                <li>
                  <Link to={`${Config.appUrl}/restaurant-systems`}>
                    Restaurant Systems and Controls
                  </Link>
                </li>
                <li>
                  <Link to={`${Config.appUrl}/ckc-metrics`}>
                    CKC Metrics Technology
                  </Link>
                </li>
                <li>
                  <Link to={`${Config.appUrl}/ckc-strategic`}>
                    CKC Strategic Partners
                  </Link>
                </li>
                <li>
                  <Link to={`${Config.appUrl}/expert-advice`}>Expert Advice</Link>
                </li>
              </div>
            </li>
            <li className="main-li">
              <Link to={`${Config.appUrl}/about`}>Company</Link>
              <div className="inner-links text-start">
                <li>
                  <Link to={`${Config.appUrl}/about`}>About Us</Link>
                </li>
                <li>
                  <Link to={`${Config.appUrl}/ourteam`}>Our Team</Link>
                </li>
                <li>
                  <Link to={`${Config.appUrl}/blog`}>Blog</Link>
                </li>
                <li>
                  <Link to={`${Config.appUrl}/testimonial`}>Testimonial</Link>
                </li>
                {/* <li>
                  <Link to={`${Config.appUrl}/gallery`}>Gallery</Link>
                </li> */}
                <li>
                  <Link to={`${Config.appUrl}/contact`}>Contact us</Link>
                </li>
                <li>
                  <Link to={`${Config.appUrl}/terms`}>Terms & Conditions</Link>
                </li>
                <li>
                  <Link to={`${Config.appUrl}/privacy-policy`}>Privacy Policy</Link>
                </li>
              </div>
            </li>
          </ul>
        </div>
      </div>

    </div>
  );
};

export default Navbar;
