import React, { useEffect, useState } from "react";
import "../../../styles/DigitalDevelopment/Websitemaintenance.css";
import Navbar from "../../../components/common/Navbar";
import "../../../styles/WebsiteDevelopment.css";
import Footer from "../../../components/common/Footer";
import CkForm from "../../../components/common/CkForm";
import websited1 from "../../../assets/Images/digital-development/websited1.avif";
import websited2 from "../../../assets/Images/digital-development/websited2.avif";
import websitem1 from "../../../assets/Images/website-maintenence/websitem1.avif";
import websitem2 from "../../../assets/Images/website-maintenence/websitem2.avif";
import websitem3 from "../../../assets/Images/website-maintenence/websitem3.avif";
import websitem4 from "../../../assets/Images/website-maintenence/websitem4.avif";
import websitem5 from "../../../assets/Images/website-maintenence/websitem5.avif";
import websitem6 from "../../../assets/Images/website-maintenence/websitem6.avif";
import Loader from "../../../components/common/Loader";
const WebsiteMaintenance = () => {
  const [loading, setLoading] = useState(false);
  useEffect(() => window.scrollTo(0, 1), []);
  // Function to toggle loading state
  const toggleLoading = (status) => {
    setLoading(status);
  };
  return (
    <div>
      {loading && <Loader/>}
      <Navbar />
      <div className=" video-container-website">
        <img src={websited1} className="video-background2"></img>
        <div className="d-flex">
          <div className="img-div " data-aos="zoom-in">
            <img src={websited2} />
          </div>
          <div className="start-up-banner  banner-position2 right container-box">
            <div data-aos="fade-left" className="">
              <h1 style={{overflow:"hidden"}}>Maintain your website for maximum performance</h1>
              <p>
                Some of our customers find it easy to manage their websites,
                while others find it difficult. That's why we provide website
                maintenance plans to help with frequent changes and keeping your
                site secure. Whether you choose occasional support or a monthly
                check-in, we will be there for you every step of the way.
              </p>

              <div className="d-flex justify-content-end highlight-txt-div">
                <h3 className="">
                  Rest assured that we will maintain your website providing you
                  peace of mind!{" "}
                </h3>
              </div>
            </div>
          </div>
        </div>
      </div>
      <br></br>
      <div className="d-flex p-90">
        <div className="d-flex gap-6 video-container2-inn">
          <div className="video-container2-inn-left">
            <h2>Features </h2>
            <h3>Small Changes</h3>
            <p>
              Make changes to your website, including content and photos, as
              needed. We have got you covered. Please let us know, and we will
              look into it wisely.{" "}
            </p>
            <h3>Google Compliance </h3>
            <p>
              There are many important steps you should take to keep your
              website up to date with Google. Otherwise, Google penalizes your
              site, making it harder for visitors to find.{" "}
            </p>
            <h3>Security</h3>
            <p>
              We truly care about the security of your website. We're aware of
              the increasing risks caused by hackers, that's why we've gone the
              extra mile to implement a three-tier security system. Our major
              goal is to ensure the security of your private data.{" "}
            </p>
            <p>
              Let's talk about creating an easy and safe shopping cart
              experience for your online customers. We're here to make it so
              simple for your clients to click the "buy now" button without
              second thinking!{" "}
            </p>
            <h3>Backups</h3>
            <p className="pb-1">
              The safety and security of your website is our top priority. But,
              well, we understand that technology can sometimes cause problems.
              Glitches are possible since the internet is constantly evolving
              and software upgrades are frequently released. That's why we have
              your back, We backup your website every 12 hours and keep them for
              up to two weeks to ensure everything runs smoothly.{" "}
            </p>
          </div>
          <div className="video-container2-inn-right pb-2">
            <div id="cover" data-aos="zoom-in">
              <img src={websitem1} />
              <img src={websitem2} />
              <img src={websitem3} />
              <img src={websitem4} />
              <img src={websitem5} />
              <img src={websitem6} />
            </div>
          </div>
        </div>
      </div>

      <CkForm toggleLoading={toggleLoading} />
      <Footer />
    </div>
  );
};

export default WebsiteMaintenance;
