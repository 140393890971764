import React, { useEffect, useState } from "react";
import "../../../styles/Company/OurTeam.css";
import Navbar from "../../../components/common/Navbar";
import Footer from "../../../components/common/Footer";
import websited1 from "../../../assets/Images/digital-development/websited1.avif";
import axios from "axios";
import Loader from "../../../components/common/Loader";
import index10 from "../../../assets/Images/Index/index10.jpg";
const OurTeam = () => {
  const [isOpen, setOpen] = useState(false);
  const [isOpen2, setOpen2] = useState(true);
  const [isOpen3, setOpen3] = useState(true);
  const [isOpen4, setOpen4] = useState(true);
  const [isOpen5, setOpen5] = useState(true);
  const [isOpen6, setOpen6] = useState(true);

  const [data, setData] = useState({});
  const [loading, setLoading] = useState(false);
  const [openItems, setOpenItems] = useState({});

  const toggleAccordion = (id) => {
    setOpenItems({
      ...openItems,
      [id]: !openItems[id],
    });
  };

  useEffect(() => {
    window.scrollTo(0, 1);
  }, []);
  // console.log(data);
  return (
    <div className="">
      {loading && <Loader />}
      <Navbar />
      <div className=" video-container-ourteam">
        <img src={index10} className="video-background"></img>
        <div></div>
        <div className="ourteam-inn">
          <div className="container col-12">
            <h1 className="text-white fw-bolder pt-5 pb-3">
              {/* {data?.ourteam?.title} */}
              Meet Our Team:
            </h1>
            <div class="accordion " data-aos="fade-right" id="accordionExample">
              <div class="accordion-item">
                <h2 class="accordion-header">
                  <button
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapseOne"
                    aria-expanded="true"
                    aria-controls="collapseOne"
                    onClick={() => {
                      setOpen(!isOpen);
                    }}
                    class="accordion-button py-4"
                  >
                    <div>Kathy - Operation Director</div>
                    <div class="accordion-icon">
                      {isOpen ? (
                        <i id="plusIcon" class="bi bi-plus"></i>
                      ) : (
                        <i id="dashIcon" class="bi bi-dash"></i>
                      )}
                    </div>
                  </button>
                </h2>
                <div
                  id="collapseOne"
                  class="accordion-collapse collapse show"
                  data-bs-parent="#accordionExample"
                >
                  <div class="accordion-body">
                    <p>
                      Meet Kathy, CK Consulting's operations manager. Kathy has
                      a decade of experience, therefore, she is the nerve center
                      of daily operations. In just five years with the company,
                      she has distinguished herself with her practicality and
                      organizational skills. The main responsibility of Kathy is
                      to ensure a flawless function by improving workflows and
                      instigating cooperation. Besides work, she is keen on the
                      Canadian outdoors and to support community volunteerism.
                      Powerful Kathy brings CK Consulting to life and
                      prosperity.
                    </p>
                    <p>
                      The main responsibility of Kathy is to ensure a flawless
                      function by improving workflows and instigating
                      cooperation. Besides work, she is keen on the Canadian
                      outdoors and to support community volunteerism. Powerful
                      Kathy brings CK Consulting to life and prosperity.
                    </p>
                  </div>
                </div>
              </div>
              <div class="accordion-item">
                <h2 class="accordion-header">
                  <button
                    class="accordion-button py-4 collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapseThree"
                    aria-expanded="false"
                    aria-controls="collapseThree"
                    onClick={() => {
                      setOpen3(!isOpen3);
                    }}
                  >
                    <div>Chirag - Managing Director</div>
                    <div class="accordion-icon">
                      {isOpen3 ? (
                        <i id="plusIcon" class="bi bi-plus"></i>
                      ) : (
                        <i id="dashIcon" class="bi bi-dash"></i>
                      )}
                    </div>
                  </button>
                </h2>
                <div
                  id="collapseThree"
                  class="accordion-collapse collapse"
                  data-bs-parent="#accordionExample"
                >
                  <div class="accordion-body">
                    <p>
                      Chirag is in charge of CK Consulting, guiding the company
                      towards innovation and expansion. Chirag has an in-depth
                      knowledge of the Canadian market as well as years of
                      consulting expertise for the firm, combining strategic
                      vision with hands-on leadership. His commitment to
                      perfection and client satisfaction sets the tone for the
                      entire team, motivating them to go above and beyond
                      expectations with each project.
                    </p>
                  </div>
                </div>
              </div>
              <div class="accordion-item">
                <h2 class="accordion-header">
                  <button
                    class="accordion-button py-4 collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapseTwo"
                    aria-expanded="false"
                    aria-controls="collapseTwo"
                    onClick={() => {
                      setOpen2(!isOpen2);
                    }}
                  >
                    <div>Julius Gohil - IT Partner - Managing Head</div>
                    <div class="accordion-icon">
                      {isOpen2 ? (
                        <i id="plusIcon" class="bi bi-plus"></i>
                      ) : (
                        <i id="dashIcon" class="bi bi-dash"></i>
                      )}
                    </div>
                  </button>
                </h2>
                <div
                  id="collapseTwo"
                  class="accordion-collapse collapse"
                  data-bs-parent="#accordionExample"
                >
                  <div class="accordion-body">
                    <p>
                      Julius Gohil is an operations head of CK Consulting, where
                      he oversees the efficient and effective day-to-day
                      operations of the company. With over a decade of business
                      experience, Julius brings a strategic approach to his
                      work, always looking for ways to increase productivity and
                      grow the company. His leadership style is collaborative,
                      with the focus on creating a positive work environment and
                      motivating team members to succeed .
                    </p>
                    <p>
                      Outside of the business, Julius is actively involved in
                      community events, which shows that CK Consulting is
                      committed to making a real impact outside the boardroom.
                    </p>
                  </div>
                </div>
              </div>

              <div class="accordion-item">
                <h2 class="accordion-header">
                  <button
                    class="accordion-button py-4 collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapseFour"
                    aria-expanded="false"
                    aria-controls="collapseFour"
                    onClick={() => {
                      setOpen4(!isOpen4);
                    }}
                  >
                    <div>Alan - Designing Consultant</div>
                    <div class="accordion-icon">
                      {isOpen4 ? (
                        <i id="plusIcon" class="bi bi-plus"></i>
                      ) : (
                        <i id="dashIcon" class="bi bi-dash"></i>
                      )}
                    </div>
                  </button>
                </h2>
                <div
                  id="collapseFour"
                  class="accordion-collapse collapse"
                  data-bs-parent="#accordionExample"
                >
                  <div class="accordion-body">
                    <p>
                      He is a Marketing Expert at CK Consulting who seeks to
                      come up with creative, individualized programs that are
                      relevant to the Canadian market. In addition to
                      exceptional analytical and creative thinking abilities, he
                      pushes the boundaries of brand engagement and growth.
                      Clients appreciate his team-player attitude as well as his
                      dedication to exceeding client expectations.
                    </p>
                    <p>
                      As an experienced strategist, his collaborative style and
                      commitment to excellence generate the results and make him
                      stand out in today's competitive corporate environment.
                    </p>
                  </div>
                </div>
              </div>
              <div class="accordion-item">
                <h2 class="accordion-header">
                  <button
                    class="accordion-button py-4 collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapseFive"
                    aria-expanded="false"
                    aria-controls="collapseFive"
                    onClick={() => {
                      setOpen5(!isOpen5);
                    }}
                  >
                    <div>Yesha Makwana - IT Partner - Operations</div>
                    <div class="accordion-icon">
                      {isOpen5 ? (
                        <i id="plusIcon" class="bi bi-plus"></i>
                      ) : (
                        <i id="dashIcon" class="bi bi-dash"></i>
                      )}
                    </div>
                  </button>
                </h2>
                <div
                  id="collapseFive"
                  class="accordion-collapse collapse"
                  data-bs-parent="#accordionExample"
                >
                  <div class="accordion-body">
                    <p>
                      Yesha Makwana acts as the IT business partner at CK
                      Consulting, where she plays a key role in process
                      improvement through cutting-edge technology. She has an
                      in-depth knowledge of IT systems and processes and
                      together with other cross-functional departments helps the
                      business to align its technology initiatives with their
                      high-level business objectives.
                    </p>
                    <p>
                      With her proactivity and dedication to what is new in the
                      field, CK Consulting benefits from her expertise in
                      providing consulting services that enhance efficiency and
                      development.
                    </p>
                  </div>
                </div>
              </div>

              <div class="accordion-item">
                <h2 class="accordion-header">
                  <button
                    class="accordion-button py-4 collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapseSix"
                    aria-expanded="false"
                    aria-controls="#collapseSix"
                    onClick={() => {
                      setOpen6(!isOpen6);
                    }}
                  >
                    <div>Tanjeet - Builder And Contractor</div>
                    <div class="accordion-icon">
                      {isOpen2 ? (
                        <i id="plusIcon" class="bi bi-plus"></i>
                      ) : (
                        <i id="dashIcon" class="bi bi-dash"></i>
                      )}
                    </div>
                  </button>
                </h2>
                <div
                  id="collapseSix"
                  class="accordion-collapse collapse"
                  data-bs-parent="#accordionExample"
                >
                  <div class="accordion-body">
                  <p>
                    Tanjeet is part of the CK Consultings crew and lets me tell you, he's the secret sauce behind our restaurant projects. Tanjeet's the kind of guy who can take your wildest restaurant dreams and turn them into reality.</p>
                      <p>
               He's got this knack for understanding exactly what you want, and then he works his magic to make it happen. From sketching out the layout to picking out the perfect paint colours, he's on it.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default OurTeam;
