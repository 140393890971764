import React, { useRef, useState, useEffect } from "react";
import Navbar from "../../components/common/Navbar";
import ServiceBanner from "../../components/common/ServiceBanner";
import Footer from "../../components/common/Footer";
import "../../styles/global.css";
import CkForm from "../../components/common/CkForm";
import start1 from "../../assets/Images/startup/start1.jpg";
import start2 from "../../assets/Images/startup/start2.jpg";
import start3 from "../../assets/Images/startup/start3.jpg";
import axios from "axios";
import Loader from "../../components/common/Loader";
const StartUp = () => {
  const [tabs, setTabs] = useState(1);

  const section1Ref = useRef(null);
  const section2Ref = useRef(null);
  const section3Ref = useRef(null);
  const section5Ref = useRef(null);
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    window.scrollTo(0, 1);
  }, []);
  const scrollToSection = (section) => {
    let targetSection = null;
    switch (section) {
      case "new-development":
        targetSection = section1Ref.current;
        break;
      case "concept-development":
        targetSection = section3Ref.current;
        setTabs(1)
        break;
      case "strategic-plan":
        targetSection = section3Ref.current;
        setTabs(2);
        break;
      case "study":
        targetSection = section3Ref.current;
        setTabs(3)
        break;
      case "marketing":
        targetSection = section5Ref.current;
        break;
      default:
        break;
    }

    if (targetSection) {
      const topOffset = targetSection.offsetTop;
      const offset = 90;
      const scrollOptions = {
        top: topOffset - offset,
        behavior: "smooth",
      };
      // targetSection.scrollIntoView(scrollOptions);
      window.scrollTo(scrollOptions);
    }
  };
  // Function to toggle loading state
  const toggleLoading = (status) => {
    setLoading(status);
  };
  console.log(tabs)
  return (
    <div>
      {loading && <Loader/>}
      <Navbar scrollToSection={scrollToSection} />
      <ServiceBanner ref={section1Ref} introSection={data?.intro_section} />
      <div className="p-90 bg-start" ref={section2Ref}>
        <div className="container">
          <div className="row g-5 align-items-center ">
            <div className="col-lg-6 p-4" data-aos="zoom-in-right">
              <img

                src={start1}
                alt="Ck Consultancy"
                height="260"
                className="w-100 object-fit-cover"
                
              />
            </div>

            <div className="col-lg-6 p-4" style={{paddingTop:"3rem"}}>
              <div className="service-about">
                <h1>
                  What You Don’t Know Can Harm Your Business
                </h1>
                <p style={{paddingTop:"5px"}}>
                  Having an appropriate business strategy for the market and
                  competition you'll face can help you start your restaurant
                  successfully.
                </p>
              </div>
            </div>

            <div className="col-lg-6 p-4" style={{paddingTop:"3rem"}}>
             
                <div className="service-about">
                  <h1> 
                    We understand what it means to start from the ground up.
                  </h1>
                  <p>
                    We become more active in areas where you require additional
                    assistance and less so in areas where you have everything
                    under control. Our crew has participated in over 50
                    restaurant openings, so having us on your side is a major
                    advantage.
                  </p>
                  <p>
                    Offer budget-friendly ways to improve the taste and appeal
                    of your cuisine. We advise our clients on the most
                    successful ways to recruit, train, and assess their team
                    members.
                  </p>
                </div>
            
            </div>

            <div className="col-lg-6 p-4" data-aos="zoom-in-left">
              <img
                src={start2}
                alt="Ck Consultancy"
                className="w-100 object-fit-cover"
                
              />
            </div>
          </div>
        </div>
      </div>

      <div className="bg-accordian p-90" ref={section3Ref}>
        <div className="bg-overlay"></div>
        <div className="container  position-relative">
          <div className="row g-5">
            <div className="col-lg-6 ">
              <div class="about-img" data-aos="zoom-out-right">
                <img
                  src={start3}
                  alt="CK Consultant"
                  className="w-100"
                />
              </div>
            </div>
            <div className="accord-section col-lg-6 m-auto" style={{paddingTop:"35px"}}>
              <h1>
                Why should you have a roadmap for your restaurant business?
              </h1>
              <p className="text-white pt-1 startup-text">
                Many new restaurant owners and start-ups require a business plan
                to secure financing. It is essentially necessary for an
                ambitious restaurateur. When creating a restaurant business
                plan, you accomplish two things:
              </p>
              <p className="text-white pt-1 startup-text">
                For newcomers to the culinary industry, a business plan is more
                than just a guide - a trusted partner on the path to success. As
                you work on creating the blueprint for your restaurant, you may
                discover problems or oversights that you were previously unaware
                of, allowing you to navigate the way with more clarity and
                insights.
              </p>
            </div>
          </div>
          <div className="row mt-5 pt-5 tabs-section">
            <div className="col-3 width bussiness-tab">
              <ul>
                <li>
                  <a
                    className={`${tabs == 1 ? "active" : ""}`}
                    onClick={() => {
                      setTabs(1);
                    }}
                  >
                    Concept Development Plan
                  </a>
                </li>
                <li>
                  <a
                    className={`${tabs == 2 ? "active" : ""}`}
                    onClick={() => {
                      setTabs(2);
                    }}
                  >
                    Strategic Business Plan
                  </a>
                </li>
                <li>
                  <a
                    className={`${tabs == 3 ? "active" : ""}`}
                    onClick={() => {
                      setTabs(3);
                    }}
                  >
                    Feasibility Study
                  </a>
                </li>
              </ul>
            </div>
            <div className="col-lg-8">
              {tabs == 1 ? (
                <div className="ms-3 tabs row align-items-center">
                  <div className="col-10">
                    <p>
                    At CK Consultings, we believe in turning ideas into actionable strategies that propel businesses forward. Our Concept Development Plan is more than just a blueprint; it's a roadmap to success.
                    </p>
                   <p>
                   Our methodology combines innovation and strategic insights to create blueprints that not only anticipate success but also pave the path for it. We create bespoke strategies that are geared to your specific vision while keeping an eye on market trends and customer behaviour. Our team collaborates from development to execution, bringing thoughts to life that resonate and persist. 
                   </p>
                  </div>
                  <div className="col-2 startup-icon">
                    <i className="bi bi-share"></i>
                  </div>
                </div>
              ) : tabs == 2 ? (
                <div className="ms-3 tabs row align-items-center">
                  <div className="col-10 ">
                    <p>
                    Crafting a roadmap for success requires foresight, strategy, and adaptability. At CK Consultings, we specialize in developing dynamic and tailored strategic business plans that serve as blueprints for growth. 
                    </p>
                    <p>
                    Our methodology combines statistical analysis and innovative thinking to ensure that each plan is as unique as the businesses we serve. From market research to financial projections, we collaborate with our clients to discover opportunities, reduce risks, and plan their path for long-term success. 
                    </p>
                  </div>
                  <div className="col-2 text-center startup-icon">
                    <i className="bi bi-file-earmark-bar-graph-fill"></i>
                  </div>
                </div>
              ) : (
                <div className="ms-3 tabs row align-items-center">
                  <div className=" col-10">
                    <p>
                    Consider your business idea as a seed that is about to sprout. Our feasibility studies act like nutritious soil, giving your project the highest chance of success. 
                    </p>
                    <p>
                    With a focus on deep research and professional analysis, we give you with a clear roadmap that highlights opportunities and minimizes risk. At CK Consultings, we understand that the economic feasibility of any venture is important for its success. 
                    </p>
                  </div>
                  <div className="col-2 text-center startup-icon">
                    <i className="bi bi-diagram-3-fill"></i>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>

      <div className="p-90">
        <div className="container py-md-4 " ref={section5Ref}>
          <div className="d-md-flex justify-content-between align-items-end">
            <div className="text-center about-section position-relative mx-auto">
              <h5>Our Planning</h5>
            </div>
          </div>
          <div className="row g-3 mt-4 marketing">
            <div className="col-4">
              <div className="ck-testimonial-box ck-testimonial-box-marketing text-center p-4">
                <i className="bi bi-people-fill new-icon"></i>
                <p className="mb-0">Developing the Top 3 Consumer Profiles</p>
              </div>
            </div>
            <div className="col-4">
              <div className="ck-testimonial-box ck-testimonial-box-marketing  text-center p-4">
                <i className="bi bi-pin-map-fill"></i>
                <p className="mb-0">
                Developing a Consumer Journey Map for Each Profile
                </p>
              </div>
            </div>
            <div className="col-4">
              <div className="ck-testimonial-box ck-testimonial-box-marketing  text-center p-4">
                <i className="bi bi-bar-chart-steps"></i>
                <p className="mb-0">
                Budgeting for Marketing and Advertising
                </p>
              </div>
            </div>
            <div className="col-4">
              <div className="ck-testimonial-box ck-testimonial-box-marketing  text-center p-4">
                <i className="bi bi-trophy-fill"></i>
                <p className="mb-0">
                Start-up and Ongoing Analysis of Top 10 Competitors
                </p>
              </div>
            </div>
            <div className="col-4">
              <div className="ck-testimonial-box ck-testimonial-box-marketing  text-center p-4">
                <i className="bi bi-person-fill-gear"></i>
                <p className="mb-0">
                  Internal Drivers; <br /> F&B Program
                </p>
              </div>
            </div>
            <div className="col-4">
              <div className="ck-testimonial-box ck-testimonial-box-marketing  text-center p-4">
                <i className="bi bi-code-square"></i>
                <p className="mb-0">
                  Digital Drivers; Requirements for Website, Social, Wi-fi,
                  mobile etc.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <CkForm toggleLoading={toggleLoading} />
      <Footer />
    </div>
  );
};

export default StartUp;
