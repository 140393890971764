import React, { useEffect, useRef, useState } from "react";
import Navbar from "../../components/common/Navbar";
import MainBanner from "../../components/MainBanner";
import Loader from "../../components/common/Loader";
import "../../styles/Loader.css";
import "aos/dist/aos.css";
import ContactSection from "../../components/common/ContactSection";
import Footer from "../../components/common/Footer";
import index1 from "../../assets/Images/Index/index1.jpg";
import index2 from "../../assets/Images/Index/index2.jpg";
import index5 from "../../assets/Images/Index/index5.avif";
import indiawalelogo from "../../assets/Images/Indiawaalelogo.png";
import image1 from "../../assets/imagegallery/image1.jpeg";
import image2 from "../../assets/imagegallery/image2.jpeg";
import image3 from "../../assets/imagegallery/image3.jpeg";
import image4 from "../../assets/imagegallery/image4.jpeg";
import image5 from "../../assets/imagegallery/image5.jpeg";
import { FaArrowRight } from "react-icons/fa";

const testimonials = [
  {
    name: "India Waale",
    comment:
      "In all aspects, CK Consultings went above and beyond our expectations. Their ability to comprehend our particular difficulties and come up with answers that matched our requirements was nothing short of amazing. We were greatly affected by the extensive knowledge possessed by the team and their proactive methods towards tasks. We appreciate partnering with them and eagerly anticipate further cooperation.",
    image: `${indiawalelogo}`,
  },
  {
    name: "Global Cuisine",
    comment:
      "CK Consulting does not only provide solutions; they anticipate a better future. We felt supported and understood at each step of our work with CK Consulting. Their team paid attention to our vision, difficulties, and goals, providing solutions that were not only effective but also reflective with our values.",
  },
];

const Index = () => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [expandedIndices, setExpandedIndices] = useState([]);
  const [activeIndex, setActiveIndex] = useState(0);
  const scrollToRef = useRef(null);

  // const fetchInfo = () => {
  //   setLoading(true);
  //   return axios
  //     .get(`${Config.apiUrl}/home`)
  //     .then((response) => {
  //       setData(response.data.data);
  //       setLoading(false);
  //       // console.log("data success", response.data);
  //     })
  //     .catch((error) => {
  //       console.error("Error :", error);
  //       setLoading(false);
  //     });
  // };

  useEffect(() => {
    // fetchInfo();
    window.scrollTo(0, 1);
  }, []);

  // Function to toggle loading state
  const toggleLoading = (status) => {
    setLoading(status);
  };

  const updateActiveIndex = () => {
    setActiveIndex((prevIndex) => (prevIndex + 1) % testimonials.length);
  };

  const ShowMoreLess = (index) => {
    if (expandedIndices.includes(index)) {
      setExpandedIndices(expandedIndices.filter((i) => i !== index));
    } else {
      setExpandedIndices([...expandedIndices, index]);
    }
  };
  // Function to handle click event and scroll to the component
  const handleClick = () => {
    scrollToRef.current?.scrollIntoView({ behavior: "smooth" });
  };

  useEffect(() => {
    const interval = setInterval(updateActiveIndex, 5000);
    return () => clearInterval(interval);
  }, [testimonials]);

  return (
    <div>
      {loading && <Loader />}
      <Navbar />
      <MainBanner bannerData={data?.banner_section} />
      <div>
        <div className="container-box p-90 px-3 ">
          <div className="row justify-content-center ">
            <div className="col-lg-8 ">
              <div className="text-center  about-section position-relative ps-md-5 pe-md-3">
                <h5>Let’s change the industry with us.</h5>
                <h1 className="text-center about-inner-h1">
                  Make Your Own Restaurant Franchise
                </h1>
                <p className="mt-3">
                  <span />
                  Begin your culinary adventure with CK Consultings and create a
                  restaurant franchise. We will guide you through each step so
                  that what you imagine becomes a tasty reality. Together let us
                  take your love for food and turn it into a Canadian chain
                  which is not only successful but also memorable on every dish
                  served. Come join us as we seek to leave our footprints in
                  this lively industry called Canadian restaurants!
                </p>
                <h3 className="text-left mt-2">
                  <span className="blink">Experience</span> = Outcome
                </h3>
                <button
                  className="btn btn-purple-about mt-3"
                  data-aos="fade-right"
                  data-aos-delay="500"
                  onClick={handleClick}
                >
                  Book Now <FaArrowRight className="ms-md-1 mb-1" />
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div ref={scrollToRef}>
        <ContactSection
          contactSection={data?.plan_section}
          toggleLoading={toggleLoading}
        />
      </div>

      <div className="p-90">
        <div className="container">
          <div className="row g-5 align-items-center">
            <div className="col-lg-6">
              <div className="row g-3 " style={{overflow:"hidden"}}>
                <div className="col-6" data-aos="fade-down-right">
                  <img
                    className="img-fluid w-100 wow zoomIn"
                    src={index1}
                    alt="About"
                  />
                </div>
                <div className="col-6" data-aos="fade-down-left">
                  <img
                    className="img-fluid w-75 wow zoomIn mt-5 pt-3"
                    src={index2}
                    alt="About"
                  />
                </div>
                <div className="col-6 text-end" data-aos="fade-up-right">
                  <img
                    className="img-fluid w-75 wow zoomIn"
                    src={index2}
                    alt="About"
                  />
                </div>
                <div className="col-6 text-end" data-aos="fade-up-left">
                  <img
                    className="img-fluid w-100 wow zoomIn"
                    src={index2}
                    alt="About"
                  />
                </div>
              </div>
            </div>

            <div className="col-lg-6 mt-4">
              <div className="contact-section">
                <h5>Online Ordering</h5>
                <h3>
                  Here's something interesting: 82% of guests prefer ordering
                  food directly from a restaurant's website.
                </h3>
                <p>
                  In just three simple steps, you can transform your restaurant
                  into a digital powerhouse for online orders, delivery, and
                  takeout.
                </p>
                <p>
                  Let the kitchen warm up with more cooking and less phone
                  ringing. With a mobile-friendly, visually appealing online
                  store that is directly linked to your kitchen printer, you can
                  reduce phone calls and order errors.
                </p>
                <h5 className="fw-bold">Steps for your digital gateway:</h5>
              </div>
              <div className="d-flex mt-4 home-card">
                <div className="stepper">
                  <div className="stepper-container ">
                    <div className="circle">
                      <i className="bi bi-telephone"></i>
                    </div>
                    <div>
                      <p>Schedule A Meeting</p>
                    </div>
                  </div>

                  <div className="line"></div>

                  <div className="stepper-container">
                    <div className="circle">
                      <i className="bi bi-file-earmark-text"></i>
                    </div>
                    <div>
                      <p>Get Your Blueprint</p>
                    </div>
                  </div>

                  <div className="line"></div>

                  <div className="stepper-container">
                    <div className="circle">
                      <i className="bi bi-bar-chart"></i>
                    </div>
                    <div>
                      <p>Get Success In Digital Realm</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* portfolio start */}
      <div className="mt-5  bg-light-pink">
        <div className="container">
          <div className="row g-5 ">
            <div className="col-lg-4 text-end">
              <div className={"service-section borders mt-5"}>
                <i className="bi bi-book-fill"></i>
                <h4>Menu Development & Branding</h4>
                <ul>
                  <li>
                    <i className="fs-6 bi bi-arrow-right me-2"></i>
                    Menu Development
                    <br />
                  </li>
                  <li>
                    <i className="fs-6 bi bi-arrow-right me-2"></i>
                    Recipe & Menu Costing
                    <br />
                  </li>
                  <li>
                    <i className="fs-6 bi bi-arrow-right me-2"></i>
                    Chef Inspired Menu Development
                    <br />
                  </li>
                  <li>
                    <i className="fs-6 bi bi-arrow-right me-2"></i>
                    Menu Engineering
                    <br />
                  </li>
                </ul>
              </div>
              <div className={"service-section  mt-5"}>
                <i className="bi bi-menu-up"></i>
                <h4>Restaurant Operations</h4>
                <ul>
                  <li>
                    <i className="fs-6 bi bi-arrow-right me-2"></i>
                    Restaurant Performance Measurement
                    <br />
                  </li>
                  <li>
                    <i className="fs-6 bi bi-arrow-right me-2"></i>
                    Menu Planning and Recipe Development
                    <br />
                  </li>
                  <li>
                    <i className="fs-6 bi bi-arrow-right me-2"></i>
                    Restaurant Systems & Controls
                    <br />
                  </li>
                  <li>
                    <i className="fs-6 bi bi-arrow-right me-2"></i>
                    CKC Metrics Technology
                    <br />
                  </li>
                </ul>
              </div>
            </div>

            <div className="col-lg-4 mt-0">
              <div className="h-100 " data-aos="zoom-in" data-aos-delay="104">
                <img
                  src={index5}
                  alt="CK"
                  className="w-100 h-100 object-fit-cover "
                />
              </div>
            </div>

            <div className="col-lg-4 text-end">
              <div className={"service-section borders mt-5"}>
                <i className="bi bi-share"></i>
                <h4>New Restaurant Development / Start Ups</h4>
                <ul>
                  <li>
                    <i className="fs-6 bi bi-arrow-right me-2"></i>
                    New Restaurant Development
                    <br />
                  </li>
                  <li>
                    <i className="fs-6 bi bi-arrow-right me-2"></i>
                    Concept Development Plan
                    <br />
                  </li>
                  <li>
                    <i className="fs-6 bi bi-arrow-right me-2"></i>
                    Strategic Business Plan
                    <br />
                  </li>
                  <li>
                    <i className="fs-6 bi bi-arrow-right me-2"></i>
                    Marketing Plan
                    <br />
                  </li>
                </ul>
              </div>
              <div className={"service-section  mt-5"}>
                <i className="bi bi-file-earmark-code-fill"></i>
                <h4>Digital Development</h4>
                <ul>
                  <li>
                    <i className="fs-6 bi bi-arrow-right me-2"></i>
                    Website Development
                    <br />
                  </li>
                  <li>
                    <i className="fs-6 bi bi-arrow-right me-2"></i>
                    Website Maintenance
                    <br />
                  </li>
                  <li>
                    <i className="fs-6 bi bi-arrow-right me-2"></i>
                    Social Media Marketing
                    <br />
                  </li>
                  <li>
                    <i className="fs-6 bi bi-arrow-right me-2"></i>
                    Restaurant SEO
                    <br />
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* portfolio end */}

      <div className="p-90 bg-light-gray">
        <div className="container py-md-4 ">
          <div className="d-md-flex justify-content-between align-items-end">
            <div className="text-center about-section position-relative mx-auto">
              <h5>Our Gallery</h5>
              <h1>Concepts in Progress</h1>
            </div>
          </div>

          <div className="mt-5">
            <div className="row our-services">
              <div
                className="col-md-3 service-card-1-img1"
                data-aos="zoom-in"
                data-aos-delay="104"
              >
                <div className="service-card-1 h-100">
                  <>
                    <img
                      src={image1}
                      alt="No Img"
                      className="w-100 h-100 object-fit-cover image1"
                    />
                    
                  </>
                </div>
              </div>
              <div className="col-md-6">
                <div className="row">
                  <div
                    className="col-6 "
                    data-aos="zoom-in"
                    data-aos-delay="103"
                  >
                    <div className="service-card-1 h-100">
                      <>
                        <img
                          src={image4}
                          alt="No Img"
                          height="173"
                          className="w-100  object-fit-cover"
                        />
                        
                      </>
                    </div>
                  </div>
                  <div
                    className="col-6"
                    data-aos="zoom-in"
                    data-aos-delay="102"
                  >
                    <div className="service-card-1 h-100">
                      <>
                        <img
                          src={image3}
                          alt="No Img"
                          height="173"
                          className="w-100  object-fit-cover image-3"
                        />
                        
                      </>
                    </div>
                  </div>
                  <div
                    className="col-5 mt-4"
                    data-aos="zoom-in"
                    data-aos-delay="100"
                  >
                    <div className="service-card-1" style={{ height: "250px" }}>
                      <>
                        <img
                          src={image2}
                          alt="No Img"
                          className="w-100 h-100 object-fit-cover"
                        />
                        
                      </>
                    </div>
                  </div>
                  <div
                    className="col-7  mt-4"
                    data-aos="zoom-in"
                    data-aos-delay="100"
                  >
                    <div className="service-card-1" style={{ height: "250px" }}>
                      <>
                        <img
                          src={image1}
                          alt="No Img"
                          className="w-100 h-100 object-fit-cover"
                        />
                        
                      </>
                    </div>
                  </div>
                </div>
              </div>
              <div
                className="col-md-3 service-card-1-img2"
                data-aos="zoom-in"
                data-aos-delay="104"
              >
                <div className="service-card-1 h-100">
                  <>
                    <img
                      src={image5}
                      alt="No Img"
                      className="w-100 h-100 object-fit-cover"
                    />
                   
                  </>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="pb-5 mb-5">
        <div className="container py-md-4 ">
          <div className="d-md-flex justify-content-between align-items-end ">
            <div className="text-center about-section position-relative mx-auto">
              <h5>OUR TESTIMONIALS</h5>
              <h1>What our clients say about us</h1>
            </div>
          </div>
          <div className="row mt-4 justify-content-center">
            {testimonials?.map((testimonial, index) => (
              <div
                className={`col-lg-4 ${
                  index === activeIndex ? "mid-active-card" : ""
                }`}
                key={index}
              >
                <div
                  className={`ck-testimonial-box p-4 ${
                    index === activeIndex ? "active" : ""
                  }`}
                >
                  <i className="bi bi-quote"></i>

                  {expandedIndices.includes(index) ? (
                    <p>{testimonial.comment}</p>
                  ) : (
                    <p>{testimonial.comment.slice(0, 200)}...</p>
                  )}
                  <button
                    onClick={() => ShowMoreLess(index)}
                    className={`btn-testimonial ${
                      index === activeIndex ? "active" : ""
                    }`}
                  >
                    {expandedIndices.includes(index)
                      ? "Show Less"
                      : "Show More"}
                  </button>

                  <div className="d-flex align-items-center mt-3">
                    {/* Assuming index12 is the image source */}
                    {index != 2 ? (
                      <img
                        src={testimonial.image}
                        width="50"
                        height="50"
                        className="testimonial-image"
                        alt=""
                      />
                    ) : null}

                    <div className="ms-3 ">
                      <h6 className="fw-bold mb-10 fs-4 ">
                        {testimonial.name}
                      </h6>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>

      <Footer />
    </div>
  );
};

export default Index;
