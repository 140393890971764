import React, { useEffect, useState } from "react";
import "../../../styles/RestaurantPerformance.css";
import Navbar from "../../../components/common/Navbar";
import Footer from "../../../components/common/Footer";
import CkForm from "../../../components/common/CkForm";
import websited1 from "../../../assets/Images/digital-development/websited1.avif"
import restaurantp1 from "../../../assets/Images/restaurant-operation/restaurantp1.webp"
import testimonial from "../../../assets/Images/company/testimonial(1).png"
import { Link } from "react-router-dom";
import { Config } from "../../../config";
import Loader from "../../../components/common/Loader";
const RestaurantPerformance = () => {
  const[loading,setLoading]=useState(false)
  useEffect(()=>window.scrollTo(0,1),[])
    // Function to toggle loading state
 const toggleLoading = (status) => {
  setLoading(status);
};  
  return (
    <div>
      {loading && <Loader />}
      <Navbar />
      <div className="video-container-erc">
        <img
          src={testimonial}
          className="video-background-erc cover"
          
          
        ></img>
        <div className="d-flex">
          <div className="start-up-banner  banner-position3 container-box ">
            <div data-aos="fade-left" className="">
              <h1>Analyzing Profit and Loss</h1>
              <p>
              Every restaurant deserves to deliver exceptional profits and a satisfying return on investment. If you're facing challenges in reaching these goals, allow us to conduct a thorough Profit and Loss Analysis Review tailored just for you.
                <br></br>
                What catches our eye? Food cost, beverage cost, labour cost, all supply cost categories, training cost, utilities, and other controllable expenses. We also consider your non-controllable expenses like rent and insurance.
                <br></br>
                <br></br>
              </p>
              
                <h3 className="fw-bolder system-txt last-txt">
                We're confident you're leaving money on the table, and we'll help you to discover it.{" "}
                </h3>
              
            </div>
          </div>
        </div>
      </div>
      <div className="break-container p-90">
        <div class="break-container-left" data-aos="fade-right">
          <h1>Break-even analysis</h1>
          <p>
          Understanding your restaurant's sales break-even point is critical for all operators. It's like possessing a key that unlocks the possibilities for profit. Knowing this threshold allows owners to determine whether their restaurant can make a profit simply by analyzing weekly or monthly sales levels.
          </p>
          <div className="">
           <Link to={`${Config.appUrl}/contact`}> <a className="btn btn-purple mt-2">
              Contact Us <i className="bi bi-arrow-right"></i>
            </a></Link>
          </div>
        </div>
        <div class="break-container-right" data-aos="fade-left">
          <img src={restaurantp1} style={{objectFit:"cover"}}/>
        </div>
      </div>
      <CkForm toggleLoading={toggleLoading}/>
      <Footer />
    </div>
  );
};

export default RestaurantPerformance;
