import React, { useEffect, useState } from "react";
import "../../../styles/RestaurantOperations/ERCStrategic.css";
import Navbar from "../../../components/common/Navbar";
import Footer from "../../../components/common/Footer";
import CkForm from "../../../components/common/CkForm"
import websited1 from "../../../assets/Images/digital-development/websited1.avif"
import websited2 from "../../../assets/Images/digital-development/websited2.avif"
import erclogo1 from "../../../assets/Images/restaurant-operation/erclogo1.jpg"
import erclogo2 from "../../../assets/Images/restaurant-operation/erclogo2.jpg"
import erclogo3 from "../../../assets/Images/restaurant-operation/erclogo3.jpg"
import erclogo4 from "../../../assets/Images/restaurant-operation/erclogo4.jpg"
import erclogo5 from "../../../assets/Images/restaurant-operation/erclogo5.jpg"
import erclogo6 from "../../../assets/Images/restaurant-operation/erclogo6.jpg"
import erclogo7 from "../../../assets/Images/restaurant-operation/erclogo7.jpg"
import Loader from "../../../components/common/Loader";
const ERCStrategic = () => {
  const[loading ,setLoading]= useState(false)
  useEffect(()=>window.scrollTo(0,1),[])
    // Function to toggle loading state
 const toggleLoading = (status) => {
  setLoading(status);
};  
  return (
    <div>
      {loading && <Loader/>}
      <Navbar />
      <div className=" video-container-strategic">
        <img
          src={websited1}
          className="video-background2 cover"
        ></img>
        <div className="d-flex">
          <div className="img-div " data-aos="zoom-in">
            <img src={websited2} />
          </div>
          <div className="start-up-banner  banner-position2 right container-box">
            <div data-aos="fade-left" className="pt-5">
              <h1>CKC Strategic partners </h1>

              <div className="d-flex justify-content-end highlight-txt-div">
                <p>
                Over the years, CKC has collaborated with various businesses and third-party suppliers, forming strong bonds with the following groups, whom we proudly consider our strategic allies. We trust in their expertise and gladly recommend them to you for any assistance you require. Feel free to explore the links provided below to discover more about each partner.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="container p-90">
      <div className="row">
        <h1 className="text-center fw-bolder pb-5">Our Partners</h1>
      <div className="col-lg-3 text-center">
          <img
            className="strategic-img"
            src={erclogo1}
          />
        </div>
        <div className="col-lg-3 text-center">
          <img
            className="strategic-img"
            src={erclogo2}
          />
        </div>
        <div className="col-lg-3 text-center">
        <img className="strategic-img" src={erclogo3} />
        </div>
        <div className="col-lg-3 text-center">
        <img className="strategic-img" src={erclogo4}/>
        </div>
      </div>
      <div className="row justify-content-between pt-5">
      <div className="col-lg-3 text-center">
        <img className="strategic-img" src={erclogo5} />
        </div>
        <div className="col-lg-3 text-center">
        <img className="strategic-img" src={erclogo6}/>
        </div>
        <div className="col-lg-3 text-center">
        <img className="strategic-img" src={erclogo7}/>
        </div>
      </div>
      </div>
      <CkForm toggleLoading={toggleLoading}/>
      <Footer/>
    </div>
  );
};

export default ERCStrategic;
