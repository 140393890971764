import React, { useEffect, useState } from 'react'
import Navbar from '../../../components/common/Navbar'
import "../../../styles/DigitalDevelopment/OnlineOrdering.css"
import Footer from '../../../components/common/Footer'
import CkForm from '../../../components/common/CkForm'
import websited1 from "../../../assets/Images/digital-development/websited1.avif"
import websited2 from "../../../assets/Images/digital-development/websited2.avif"
import Loader from '../../../components/common/Loader'
const OnlineOrdering = () => {
  const[loading,setLoading] = useState(false)
  useEffect(()=>window.scrollTo(0,1),[])
    // Function to toggle loading state
 const toggleLoading = (status) => {
  setLoading(status);
};  
  return (
    <div>
      {loading && <Loader/>}
      <Navbar/>
      <div className=" video-container-online">
        <img
          src={websited1}
          className="video-background2 cover"
        ></img>
        <div className="d-flex">
          <div className="img-div " data-aos="zoom-in">
            <img src={websited2} />
          </div>
          <div className="start-up-banner  banner-position2 banner-position5 right container-box">
            <div data-aos="fade-left" className="">
              <h1 style={{overflow:"hidden"}}>Transform your restaurant into a digital powerhouse of delivery and ordering </h1>
             
              <div className="d-flex justify-content-end highlight-txt-div">
                <h3 className="">
                82% of Guests Prefer Ordering Food Online from restaurant website{" "}
                </h3>
                
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="p-90">
        <div className="container py-md-4 ">
          <div className="d-md-flex justify-content-between align-items-end">
            <div className="text-center about-section position-relative mx-auto">
              <h5>Online Order</h5>
              <h1>Have a Look</h1>
            </div>
          </div>
          <div className="row g-3 mt-4 marketing">
            <div className="col-4">
              <div className="ck-testimonial-box text-center p-4">
              <i class="bi bi-telephone-fill"></i>
                <p className="mb-0">
                Schedule a Call
                </p>
              </div>
            </div>
            <div className="col-4"> 
              <div className="ck-testimonial-box text-center p-4">
              <i class="bi bi-journals"></i>
                <p className="mb-0">
                Get Your Plan
                </p>
              </div>
            </div>
            <div className="col-4">
              <div className="ck-testimonial-box text-center p-4">
                <i className="bi bi-code-square"></i>
                <p className="mb-0">
                Win Online
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <CkForm toggleLoading={toggleLoading}/>
      <Footer/>
    </div>
  )
}

export default OnlineOrdering
