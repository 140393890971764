import React, { useEffect, useState } from "react";
import "../../../styles/RestaurantOperations/RestaurantSystems.css";
import Navbar from "../../../components/common/Navbar";
import Footer from "../../../components/common/Footer";
import CkForm from "../../../components/common/CkForm";
import websited1 from "../../../assets/Images/digital-development/websited1.avif";
import websited2 from "../../../assets/Images/digital-development/websited2.avif";
import menuplan1 from "../../../assets/Images/restaurant-operation/menuplan1.avif";
import menuplan2 from "../../../assets/Images/restaurant-operation/menuplan2.avif";
import { Link } from "react-router-dom";
import { Config } from "../../../config";
import Loader from "../../../components/common/Loader";
const RestaurantSystems = () => {
  const [loading, setLoading] = useState(false);
  useEffect(() => window.scrollTo(0, 1), []);
  // Function to toggle loading state
  const toggleLoading = (status) => {
    setLoading(status);
  };
  return (
    <div>
    {loading && <Loader/>}
      <Navbar />
      <div className="video-container-system">
        <img src={websited1} className="video-background2"></img>
        <div className="d-flex">
          <div className="img-div " data-aos="zoom-in">
            <img src={websited2}className="cover"/>
          </div>
          <div className="start-up-banner  banner-position2 banner-position4 right container-box">
            <div data-aos="fade-left" className="">
              <h1>Restaurant Operational Management</h1>
              <h2 className="fw-bolder system-txt">CKC Brand Audit</h2>
              <p className="text-white">
                Elevating operational excellence with the following services:
              </p>

              <p className="text-white ">
                Brand Audit: Evaluates and keeps track of distinct brand
                features, how the brand is presented, as well as the quality and
                service provided.
              </p>

              <p className="text-white ">
                Food Safety Audit: On-site inspections and training sessions
                aimed at enhancing food safety practices, along with
                comprehensive reporting, to elevate food safety standards.
              </p>

              <p className="text-white ">
                Guest Experience Audit: In-person reviews of metrics set by the
                client to improve guest satisfaction and encourage repeat
                visits.
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className="container p-90">
        <div className="row restaurant-system-inn">
          <div
            className="col-lg-6 text-center menuplan-col1"
            data-aos="fade-right"
          >
            <img className="menuplan-img cover" src={menuplan1} />
          </div>
          <div className="col-lg-6 menuplan-col1 " data-aos="fade-left" >
            <h3 className="font-styled">
              Superiority, Planned, and Outstanding Performance
            </h3>
            <p>
              In the absence of effective systems, restaurants become chaotic,
              delivering unreliable outcomes, and leaving the owner to operate
              more like another staff member than a true leader.
            </p>
            <p>
              In the restaurant world, systems are like the secret sauce that
              keeps everything running smoothly. Think checklists for opening,
              closing, and keeping things squeaky clean, clear steps for finding
              and welcoming new team members, sticking to tried-and-true recipes
              in the kitchen, and having clear protocols for handling any
              hiccups along the way, from guest complaints to stocking up on
              supplies and taking stock of what's in the pantry.
            </p>
            <p>
              Systems are important in restaurants for a number of reasons.
              Primarily, they improve your ability to offer consistent eating
              experiences to your valued customers.
            </p>
          </div>
        </div>
      </div>
      <div className="container restaurant-system">
        <div className="row restaurant-system-inn">
          <div className="col-lg-6 " data-aos="fade-right" style={{padding:"5px"}}>
            <h3 className="font-styled">Food Safety</h3>
            <p>
              To ensure that your restaurant serves safe meals, we evaluate your
              present food handling procedures and give recommendations to
              comply with your local health department's laws. We focus on
              facility food safety compliance, which includes receiving and
              storage, staff food handling, and temperature controls.
            </p>
            <p>
              Discover the art of choosing, managing, and cooking food safely to
              safeguard yourself and others from foodborne illnesses.
            </p>
            <p>
              Use a digital thermometer to ensure that meat, poultry, and
              shellfish have the proper interior temperature. Recognize the
              importance of good food storage in reducing the risk of
              food-related illnesses. To keep yourself and your loved ones safe,
              learn more about food poisoning and how to properly clean food.
            </p>
            <div className="">
              <Link to={`${Config.appUrl}/contact`}>
                {" "}
                <a className=" btn btn-purple mt-2">
                  Contact Us <i className="bi bi-arrow-right"></i>
                </a>
              </Link>
            </div>
          </div>
          <div className="col-lg-6 text-center" data-aos="fade-left">
            <img className="menuplan-img" src={menuplan2} />
          </div>
        </div>
      </div>
      <CkForm toggleLoading={toggleLoading} />
      <Footer />
    </div>
  );
};

export default RestaurantSystems;
