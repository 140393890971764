import React, { useEffect, useState } from "react";
import "../../../styles/Company/Blog.css";
import Navbar from "../../../components/common/Navbar";
import Footer from "../../../components/common/Footer";
import Loader from "../../../components/common/Loader";
import { Link } from "react-router-dom";
import { Config } from "../../../config";
import axios from "axios";
import Testimonial  from "../../../assets/Images/company/testimonial(1).png"
const Blog = () => {
  const [data, setData] = useState([]);
  const [converteddate,setConvertedDate] = useState([])

  const [loading, setLoading] = useState(false);
  const fetchInfo = () => {
    setLoading(true);
    return axios
      .get(`${Config.apiUrl}/blogs`)
      .then((response) => {
        setData(response.data.data);
        setLoading(false);
        // console.log("data success", response.data);
      })
      .catch((error) => {
        console.error("Error :", error);
        setLoading(false);
      });
  };

  const convertDate = (index)=>{
    let date = new Date(data?.data?.blogs[index]?.updated_at);
  
    let dateMDY = `${date.getDate()}-${date.getMonth() + 1}-${date.getFullYear()}`;
      setConvertedDate((prev)=>{
        return[...prev,dateMDY]

      })
    }

  




  
  useEffect(() => {
    window.scrollTo(0, 1);
    fetchInfo();
  }, []);
  // console.log(data);
  return (
    <div>
      {loading && <Loader />}
      <Navbar />
      <div className=" video-container-blog">
        <img src={Testimonial} className="video-background"></img>
        <div className="blog-inn">
          <div className="container col-lg-12 py-5 d-flex blog-container">
            <div className="col-lg-9 blog-inn-left">
              <div class="card-group">
                {data?.blogs?.slice(0, 3).map((item, index) => (
                  <div class="card card-blog" key={index}>
                   <Link
                      className="blog-links"
                      to={`${Config.appUrl}/blog/${item.slug}`}
                    >
                      <small class="text-body-secondary" >
                        {" "}
                        <img src={item.image} class="card-img-top blog-img cover" alt="..." />
                      </small>
                    </Link>
                    <div class="card-body">
                      <h5 class="card-title">
                        <Link
                          className="blog-links"
                          to={`${Config.appUrl}/blog/${item.slug}`}
                        >
                          <small class="text-body-secondary fw-bolder">
                            {item.title}
                          </small>
                        </Link>
                      </h5>
                      
                      
                      <p class="card-text">{item.content}</p>
                      <p class="card-text">
                        <Link
                          className="blog-links"
                          to={`${Config.appUrl}/blog/${item.slug}`}
                        >
                          <small class="text-body-secondary">Read more</small>
                        </Link>
                      </p>
                    </div>
                  </div>
                ))}
              </div>
              <div class="card-group">
                {data?.blogs?.slice(3, 6).map((item, index) => (
                 
                  <div class="card card-blog" key={index}>
                    <Link
                      className="blog-links"
                      to={`${Config.appUrl}/blog/${item.slug}`}
                    >
                      <small class="text-body-secondary">
                        {" "}
                        <img src={item.image} class="card-img-top blog-img" alt="..." />
                      </small>
                    </Link>
                    <div class="card-body">
                      <h5 class="card-title">
                        <Link
                          className="blog-links"
                          to={`${Config.appUrl}/blog/${item.slug}`}
                        >
                          <small class="text-body-secondary fw-bolder">
                            {item.title}
                          </small>
                        </Link>
                      </h5>
                      
                      <p class="card-text">{item.content}</p>
                      <p class="card-text">
                        <Link
                          className="blog-links"
                          to={`${Config.appUrl}/blog/${item.slug}`}
                        >
                          <small class="text-body-secondary">Read more</small>
                        </Link>
                      </p>
                    </div>
                  </div>
                ))}
              </div>
              <div class="card-group">
                {data?.blogs?.slice(6).map((item, index) => (
                  
                  <div class="card card-blog" key={index}>
                  <Link
                      className="blog-links"
                      to={`${Config.appUrl}/blog/${item.slug}`}
                    >
                      <small class="text-body-secondary">
                        {" "}
                        <img src={item.image} class="card-img-top blog-img cover" alt="..." />
                      </small>
                    </Link>
                    <div class="card-body">
                      <h5 class="card-title">
                        <Link
                          className="blog-links"
                          to={`${Config.appUrl}/blog/${item.slug}`}
                        >
                          <small class="text-body-secondary fw-bolder">
                            {item.title}
                          </small>
                        </Link>
                      </h5>
                      
                      
                      <p class="card-text">{item.content}</p>
                      <p class="card-text">
                        <Link
                          className="blog-links"
                          to={`${Config.appUrl}/blog/${item.slug}`}
                        >
                          <small class="text-body-secondary">Read more</small>
                        </Link>
                      </p>
                    </div>
                  </div>
                ))}
              </div>
            </div>
            <div className="col-lg-3 py-4 blog-bottom">
              <div className="blog-right">
                <div class=" px-3 pb-5 pt-3">
                  <h4>Recent Posts</h4>
                  {data?.recent_post?.slice(0, 5).map((item, index) => (
                    <div className="blog-right-txt" key={index}>
                      <Link
                        className="blog-links"
                        to={`${Config.appUrl}/blog/${item.slug}`}
                      >
                        <div class="text-body-secondary">{item.title}</div>
                      </Link>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default Blog;
