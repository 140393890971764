import React, { useEffect, useState } from "react";
import Navbar from "../../../components/common/Navbar";
import "../../../styles/WebsiteDevelopment.css";
import Footer from "../../../components/common/Footer";
import CkForm from "../../../components/common/CkForm";
import testimonial from "../../../assets/Images/company/testimonial(1).png"
import websited1 from "../../../assets/Images/digital-development/websited1.avif"
import websited2 from "../../../assets/Images/digital-development/websited2.avif"
import websited3 from "../../../assets/Images/digital-development/websited3.avif"
import websited4 from "../../../assets/Images/digital-development/websited4.avif"
import websited5 from "../../../assets/Images/digital-development/websited5.avif"
import websited6 from "../../../assets/Images/digital-development/websited6.avif"
import Loader from "../../../components/common/Loader";
const WebsiteDevelopment = () => {
  const[loading,setLoading]=useState(false)
  useEffect(()=>window.scrollTo(0,1),[])
    // Function to toggle loading state
 const toggleLoading = (status) => {
  setLoading(status);
};  
  return (
    <div>
      {loading && <Loader/>}
      <Navbar />
      <div className=" video-container-website">
        <img
          src={testimonial}
          className="video-background2"
        ></img>
        <div className="d-flex">
          <div className="img-div " data-aos="zoom-in">
            <img src={websited2} style={{objectFit:"cover"}}/>
          </div>
          <div className="start-up-banner  banner-position2 right container-box">
            <div data-aos="fade-left" className="">
              <h1 style={{overflow:"hidden"}}>A Website is your 24/7 promoter </h1>
              <p>
              In today's digital world, having an excellent website is crucial for every business, especially restaurants. You're losing out on a lot if you don't have an attractive website that demonstrates the personality of your brand!
              </p>
              <p>
              To survive, a website must welcome customers with an attractive UI/UX design that facilitates navigation and encourages them to take action. This manner, people will be more likely to contact you for help.
              </p>
              <div className="d-flex justify-content-end highlight-txt-div">
                <h3 className="">
                When it comes to selecting whether to trust or avoid a website, 94% of people are influenced by the way it looks.{" "}
                </h3>
              </div>
            </div>
          </div>
        </div>
      </div>
      <br></br>
      <div className="d-flex p-90">
        <div className="d-flex video-container2-inn">
          
          <div className="video-container2-inn-left">
            <h2>Our web development services are designed for helping your business to grow and achieve new heights. </h2>
            <h3>Responsive Websites</h3>
            <p>
            Your website should be flexible, fitting quickly onto every screen, no matter how small or large. We'll make sure your website works seamlessly on all platforms, from smartphones and tablets to desktop computers, so your customers can simply contact with you no matter where they are.
            </p>
            <h3>Excellent user experience</h3>
            <p>
            Make sure your website is simple to navigate. If folks can't find what they're after, they're apt to bounce, leading to fewer folks completing actions on your site.
            </p>
            <p>
            We'll straighten up your website's navigation and add a few obvious buttons to help you and your visitors locate what you're looking for. It will be as easy as pie!
            </p>
            <h3>Ecommerce Website Design</h3>
            <p>
            Are you ready for extending your traditional store into the online world? Do you want to share your products with a wider audience?{" "}
            </p>
            <p>
            Let's discuss about ensuring your online shoppers feel comfortable and confident throughout their purchase experience. We're here to ensure that your clients may comfortably click the "buy now" button!
            </p>
          </div>
          <div className="video-container2-inn-right">
            <div id="cover" data-aos="zoom-in">
              <img src={websited3} />
              <img src={websited4} />
              <img src={websited5} />
              <img src={websited6} />
            </div>
          </div>
        </div>
      </div>
      <div className="d-md-flex justify-content-between align-items-end">
        <div className="text-center about-section position-relative mx-auto">
          <h5>Our Process</h5>
        </div>
      </div>
      <section class="d-flex justify-content-center main-sectionn">
        <div class="cardd" data-aos="zoom-in">
          <div className="ck-testimonial-box3 text-center p-4">
            <i className="bi bi-people-fill"></i>
            <h3 className="mb-0">Explore your business and design tastes.</h3>
            <p className="pt-3">
            We will send you a quick survey to help us understand what you are looking for in your website. This is a chance to tell us what features you would like to see on your website.
            </p>
          </div>
        </div>
        <div class="cardd" data-aos="zoom-in">
          <div className="ck-testimonial-box3 text-center p-4">
            <i className="bi bi-bar-chart-steps"></i>
            <h3 className="mb-0">
            Establish Strong Online Presence: Website Hosting and It's Platform
            </h3>
            <p className="pt-3">
              {" "}
              To create your website, we will need access to your hosting account. Do not worry if you haven't got a setup! We can recommend some choices that will meet your needs.
            </p>
          </div>
        </div>
        <div class="cardd" data-aos="zoom-in">
          <div className="ck-testimonial-box3 text-center p-4">
            <i className="bi bi-person-fill-gear"></i>
            <h3 className="mb-0">Get Your Online Home Started</h3>
            <p className="pt-3">
            We will provide you the initial version of your website for review once it completed. Your happiness is extremely important to us, thus we want to make sure it exactly matches with your vision.  We respect your feedback and will make the necessary changes to ensure that your website meets your expectations.
            </p>
          </div>
        </div>
        <div class="cardd" data-aos="zoom-in">
          <div className="ck-testimonial-box3 text-center p-4">
            <i className="bi bi-code-square"></i>
            <h3 className="mb-0">Bring your website to life</h3>
            <p className="pt-3">
            Once you approve the website design, we will make it online for you. We will move it from its temporary placement to the main domain. From there, you'll begin to enjoy all of the benefits of your new website, which will provide an improved user experience that you'll like!
            </p>
          </div>
        </div>
      </section>
      <CkForm toggleLoading={toggleLoading}/>
      <Footer />
    </div>
  );
};

export default WebsiteDevelopment;
