import React, { useEffect, useState } from "react";
import "../../../styles/Company/Testimonial.css";
import Navbar from "../../../components/common/Navbar";
import Footer from "../../../components/common/Footer";
import Loader from "../../../components/common/Loader";
import indiawalelogo from "../../../assets/Images/Indiawaalelogo.png";
import testimonial from "../../../assets/Images/company/testimonial(1).png"

const testimonials = [
  {
    name: "India Waale",
    comment:
      "In all aspects, CK Consultings went above and beyond our expectations. Their ability to comprehend our particular difficulties and come up with answers that matched our requirements was nothing short of amazing. We were greatly affected by the extensive knowledge possessed by the team and their proactive methods towards tasks. We appreciate partnering with them and eagerly anticipate further cooperation.",
    image: `${indiawalelogo}`,
  },
  {
    name: "Global Cuisine",
    comment:
      "CK Consulting does not only provide solutions; they anticipate a better future. We felt supported and understood at each step of our work with CK Consulting. Their team paid attention to our vision, difficulties, and goals, providing solutions that were not only effective but also reflective with our values.",
  },
];



const Testimonial = () => {
  const [loading, setLoading] = useState(false);
  const [expandedIndices, setExpandedIndices] = useState([]);
  const [activeIndex, setActiveIndex] = useState(0);

 

  useEffect(() => {
    window.scrollTo(0, 1);
    
  }, []);

  const updateActiveIndex = () => {
    setActiveIndex((prevIndex) => (prevIndex + 1) % testimonials.length);
  };

  const ShowMoreLess = (index) => {
    if (expandedIndices.includes(index)) {
      setExpandedIndices(expandedIndices.filter((i) => i !== index));
    } else {
      setExpandedIndices([...expandedIndices, index]);
    }
  };

  useEffect(() => {
    const interval = setInterval(updateActiveIndex, 5000);
    return () => clearInterval(interval);
  }, [testimonials]);

  return (
    <div>
      {loading && <Loader />}
      <Navbar />
      <div className=" video-container video-container-testimonial">
        <img
          src={testimonial}
          className="video-background2"
        />
        <div className="d-flex text-right">
          <div className="start-up-banner  banner-position right container-box">
            <div data-aos="fade-left" className="text-right">
              <h1>Our Testimonials</h1>
              <div className="d-flex  highlight-txt-div justify-content-end">
                <h3>What our clients say about us</h3>
              </div>
              <p>	Some of our clients have the time and general knowledge to maintain their website. Some of them do not. We offer website maintenance plans to assist you with routine updates and security of your website. Whether you need us on an “as needed” or monthly basis, we will be here to support you.</p>
              <div className="d-flex  highlight-txt-div">
                <h3>Rest Easy Knowing We Will Keep Your Site Secure, Backed Up, And Google Compliant!</h3>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="pb-5 mb-5 pt-5 mt-5">
        <div className="container py-md-4 ">
          <div className="d-md-flex justify-content-between align-items-end ">
            <div className="text-center about-section position-relative mx-auto">
              <h5>OUR TESTIMONIALS</h5>
              <h1>What our clients say about us</h1>
            </div>
          </div>
          <div className="row mt-4 justify-content-center">
            {testimonials?.map((testimonial, index) => (
              <div
                className={`col-lg-4 ${
                  index === activeIndex ? "mid-active-card" : ""
                }`}
                key={index}
              >
                <div
                  className={`ck-testimonial-box p-4 ${
                    index === activeIndex ? "active" : ""
                  }`}
                >
                  <i className="bi bi-quote"></i>

                  {expandedIndices.includes(index) ? (
                    <p>{testimonial.comment}</p>
                  ) : (
                    <p>{testimonial.comment.slice(0, 200)}...</p>
                  )}
                  <button
                    onClick={() => ShowMoreLess(index)}
                    className={`btn-testimonial ${
                      index === activeIndex ? "active" : ""
                    }`}
                  >
                    {expandedIndices.includes(index)
                      ? "Show Less"
                      : "Show More"}
                  </button>

                  <div className="d-flex align-items-center mt-3">
                    {/* Assuming index12 is the image source */}
                    {index !== 2 ? (
                      <img
                        src={testimonial.image}
                        width="50"
                        height="50"
                        className="testimonial-image"
                        alt=""
                      />
                    ) : null}

                    <div className="ms-3 ">
                      <h6 className="fw-bold mb-10 fs-4 ">
                        {testimonial.name}
                      </h6>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>

      <Footer />
    </div>
  );
};

export default Testimonial;
