import React, { useEffect, useState } from "react";
import "../../../styles/RestaurantOperations/ERCMetrics.css";
import Navbar from "../../../components/common/Navbar";
import Footer from "../../../components/common/Footer";
import CkForm from "../../../components/common/CkForm";
import websited1 from "../../../assets/Images/digital-development/websited1.avif";
import websited2 from "../../../assets/Images/digital-development/websited2.avif";
import ercmetric1 from "../../../assets/Images/restaurant-operation/ercmetric1.webp"
import { Link } from "react-router-dom";
import { Config } from "../../../config";
import Loader from "../../../components/common/Loader";
const ERCMetrics = () => {
  const [loading, setLoading] = useState(false);
  useEffect(() => window.scrollTo(0, 1), []);
  // Function to toggle loading state
  const toggleLoading = (status) => {
    setLoading(status);
  };
  return (
    <div>
      {loading && <Loader/>}
      <Navbar />
      <div className=" video-container-erc">
        <img src={websited1} className="video-background-erc cover" ></img>
        <div className="d-flex">
          <div className="img-div " data-aos="zoom-in">
            <img src={websited2} />
          </div>
          <div className="start-up-banner  banner-position-metrics banner-position2 right container-box">
            <div data-aos="fade-left" className="">
              <h1>Cost Evaluation</h1>
              <h2 className="fw-bolder system-txt">Lowering of cost</h2>
              <p className="text-white ">
                If you want to manage your expenses effectively and increase
                your earnings, calculate and check the highest cost on a weekly
                basis. Keeping track of your top expenses each week will greatly
                improve your capacity to minimize spending, resulting in a clear
                and dramatic rise in your bottom line.
              </p>

              <p className="text-white ">
                The highest cost is an important element in every restaurant's
                profit and loss statement. This figure combines sales and
                payroll expenses, indicating the most fluctuating costs that
                require watchful monitoring. ignoring controls in food,
                beverage, and payroll can result in major financial losses.
              </p>
              <h2 className="fw-bolder system-txt">
                Weekly Prime Cost: A Customer-Centric Approach
              </h2>
            </div>
          </div>
        </div>
      </div>
      <div className="container p-90">
        <div className="row">
          <div className="col-lg-6 menuplan-col1 " data-aos="fade-right" style={{paddingTop:"5px"}}>
            <h3 className="font-styled">Real Food Expenses</h3>
            <p>
              In a restaurant, the prime cost encompasses all labour expenses
              (including salaries, hourly wages, benefits, etc.) and its COGS.
              This metric holds significance as it reflects the major portion of
              controllable expenses for the restaurant.
            </p>
            <p>
              While you can't influence fixed rent expenses on a weekly or
              monthly basis, you do have the power to reduce prime costs by
              carefully managing labour. This means that a restaurant's prime
              costs offer the main opportunity for a restaurant owner to cut
              expenses and boost profits.
            </p>
            <p>
              Running prime cost calculations weekly, 52 times annually, rather
              than monthly, offers restaurants an effective means to promptly
              address any issues that may arise.
            </p>
          </div>
          <div
            className="col-lg-6 text-center menuplan-col1"
            data-aos="fade-left"
          >
            <img className="menuplan-img" src={ercmetric1} style={{objectFit:"cover"}}/>
          </div>
        </div>
      </div>
      <div>
        <div className=" erc-div" >
          <div className="erc-div2" data-aos="fade-left">
            <h1 className="fw-bolder">
              Dining Experience with Restaurant POS Technology
            </h1>
            <p className="fw-bolder">
              A quality point-of-sale system ensures smooth communication
              between the kitchen and the front of the house, reduces the risk
              of employee theft, prevents early or late clock-ins, tracks
              popular menu items and who's selling them, facilitates daily price
              adjustments, provides valuable tip reporting data, supports
              employee incentive schemes, influences menu design, and offers
              additional benefits. When fully utilized, such a system can boost
              the bottom line by 1-2%.
            </p>
            <p className="fw-bolder">
              Prime cost is an important item on any restaurant's profit and
              loss statement. This figure combines sales and payroll expenses,
              indicating the most volatile costs that require careful
              management. Neglecting controls in food, beverage, and payroll can
              quickly result in financial losses.
            </p>
            <p className="fw-bolder">
              In today's ever-evolving restaurant scene, Canadian food business
              operators have access to technological tools that were
              non-existent just a year ago. We understand these solutions inside
              out, whether it's for enhancing customer service or streamlining
              kitchen operations, and we're here to guide you in choosing the
              best fit for your business.
            </p>
            <div className="">
              <Link to={`${Config.appUrl}/contact`}>
                {" "}
                <a className="btn btn-purple mt-2">
                  Contact Us <i className="bi bi-arrow-right"></i>
                </a>
              </Link>
            </div>
          </div>
        </div>
      </div>
      <CkForm toggleLoading={toggleLoading} />
      <Footer />
    </div>
  );
};

export default ERCMetrics;
