import React, { useEffect, useState } from "react";
import "../../../styles/RestaurantOperations/MenuPlanning.css";
import Navbar from "../../../components/common/Navbar";
import Footer from "../../../components/common/Footer";
import CkForm from "../../../components/common/CkForm";
import menuplan1 from "../../../assets/Images/restaurant-operation/menuplan1.avif"
import menuplan2 from "../../../assets/Images/restaurant-operation/menuplan2.avif"
import Loader from "../../../components/common/Loader";
const MenuPlanning = () => {
  const[loading,setLoading]=useState(false)
  useEffect(()=>window.scrollTo(0,1),[])
    // Function to toggle loading state
 const toggleLoading = (status) => {
  setLoading(status);
};  
  const starter = [
    {
      name: "Delicious Starter",
      price: "$13.00",
    },
    {
      name: "To Share",
      price: "$7.00",
    },
    {
      name: "Best Option",
      price: "$9.00",
    },
    {
      name: "Starter 1",
      price: "$11.00",
    },
    {
      name: "Starter 2",
      price: "$16.00",
    },
  ];
  const burger = [
    {
      name: "Delicious Meat",
      price: "$14.00",
    },
    {
      name: "Best Burger",
      price: "$13.50",
    },
    {
      name: "Authentic Grill",
      price: "$12.00",
    },
    {
      name: "BBQ Meat",
      price: "$14.80",
    },
    {
      name: "Best Taste",
      price: "$13.50",
    },
  ];

  const desert = [
    {
      name: "Brownie",
      price: "$4.50",
    },
    {
      name: "Best Cheesecake",
      price: "$6.00",
    },
    {
      name: "Carrot Cake",
      price: "$5.00",
    },
    {
      name: "Chocolate",
      price: "$3.00",
    },
    {
      name: "Apple Pie",
      price: "$6.00",
    },
  ];

  const drink = [
    {
      name: "Drink",
      price: "$2.00",
    },
    {
      name: "Beer",
      price: "$3.00",
    },
    {
      name: "Cocktail",
      price: "$8.00",
    },
  ];

  return (
    <div>
      {loading && <Loader/>}
      <Navbar />
      <div className="bg-menu position-relative ">
        <div className="bg-overlay"></div>
        <div className="">
          <div className="row position-relative container-box menu-planning-banner">
            <div className="col-6 container-box1">
              <div className="accord-section col-12 m-auto menu-side-section">
                <h1>Crafting Culinary Delights</h1>

                <p className="text-white">
                Making a perfect menu requires a combination of ideas and expertise. Menu engineering reveals the financial story behind your dishes, emphasizing both the successes and the struggles. With variable food costs, a one-size-fits-all pricing plan will not work; instead, implement smart changes that resonate with your loyal customers.
                </p>
                <p className="text-white">
                One of the simplest methods to carry out menu engineering is by linking your inventory tracking with your point of sale system. Look for an integrated inventory software that enables you to consistently assess which items on your menu are thriving and which ones are lagging. An effective strategy to counteract the increase in food prices is to prioritize selling more of your most lucrative dishes.
                </p>

                <p className="text-white">
                Continually updating your menu ensures it stays fresh and relevant with the seasons. It also ensures that your prices accurately reflect the current ingredient costs for all menu items.
                </p>
                <div className="d-flex flex-column justify-content-end highlight-txt-div">
                  <h3>Boost your profits by <br/> 15% or higher</h3>
                </div>
              </div>
            </div>
            <div className="col-md-6  container-box2 ps-md-5">
              <div className="cta-section">
                <h5 className="text-white">Our Menu</h5>
                <h2 className="text-white">Menu Details</h2>
              </div>
              <div className="mt-3">
                <h3 className="text-white">Starters</h3>
                {starter?.map((obj, i) => {
                  return (
                    <div class="menu-content col-10 align-items-center" key={i} style={{paddingTop:"5px"}} >
                      <h6 className="text-white fw-bold mb-0 menu-heading">{obj.name}</h6>
                      <span
                        className="col-7 dotted-line" id={i}
                        style={{ border: "1px dashed white", height: "1px" }}
                      ></span>
                      <span className="text-white mb-0 price">{obj.price}</span>
                    </div>
                  );
                })}
              </div>
              <div className="mt-3" style={{paddingTop:"5px"}}>
                <h3 className="text-white">Burgers</h3>
                {burger?.map((obj, i) => {
                  return (
                    <div class="menu-content col-10 align-items-center" key={i}>
                      <h6 className="text-white fw-bold mb-0 menu-heading">{obj.name}</h6>
                      <span
                        className="col-7 dotted-line"
                        style={{ border: "1px dashed white", height: "1px" }}
                      ></span>
                      <span className="text-white mb-0 price">{obj.price}</span>
                    </div>
                  );
                })}
              </div>
              <div className="mt-3" style={{paddingTop:"5px"}}>
                <h3 className="text-white">Desserts</h3>
                {desert?.map((obj, i) => {
                  return (
                    <div class="menu-content col-10 align-items-center" key={i}>
                      <h6 className="text-white fw-bold mb-0 menu-heading">{obj.name}</h6>
                      <span
                        className="col-7 dotted-line"
                        style={{ border: "1px dashed white", height: "1px" }}
                      ></span>
                      <span className="text-white mb-0 price">{obj.price}</span>
                    </div>
                  );
                })}
              </div>
              <div className="mt-3" style={{paddingTop:"5px"}}>
                <h3 className="text-white">Drinks</h3>
                {drink?.map((obj, i) => {
                  return (
                    <div class="menu-content col-10 align-items-center" key={i}>
                      <h6 className="text-white fw-bold mb-0 menu-heading ">{obj.name}</h6>
                      <span
                        className="col-7 dotted-line"
                        style={{ border: "1px dashed white", height: "1px" }}
                      ></span>
                      <span className="text-white mb-0 price">{obj.price}</span>
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="container p-90">
        <div className="row">   
        <div className="col-lg-6 menuplan-col1 " data-aos="fade-right">
            <h3 className="font-styled">Customer Interests</h3>
            <p> 
            With our menu engineering skills, we work together with you to build a diversified menu that combines premium and low-cost items while merging current and traditional meals to suit your interests and preferences.
            </p>
            <p>
            CKC knows what customers love to see on menus, whether they're into upscale dining or quick bites, and we'll work with you to include those must-have dishes and ingredients that keep them coming back for more.
            </p>
            <p>
            Furthermore, by carefully selecting your menu, organizing your space, and carefully arranging products, we help to influence customer selections, resulting in increased sales for your most profitable dishes. 
            </p>
          </div>
          <div className="col-lg-6 text-center menuplan-col1" data-aos="fade-left">
            <img
              className="menuplan-img"
              src={menuplan1}
              style={{objectFit:"cover"}}
            />
          </div>
        </div>
      </div>
      <div className="container">
        <div className="row ">
          <div className="col-lg-6 text-center" data-aos="fade-right">
            <img
              className="menuplan-img"
              src={menuplan2}
              style={{objectFit:"cover"}}
            />
          </div>
          <div className="col-lg-6 menu-costing" data-aos="fade-left">
            <h3 className="font-styled">Menu Cost Calculation</h3>
            <p>Are menu prices set to meet profit targets?</p>
            <p>
            If you don't update your menu costs on a regular basis, the information will become outdated and unreliable. To keep ahead of the competition in this ever-changing industry, menu costs must be reviewed and adjusted on a regular basis. How can you explain price hikes if the menu isn't appropriately priced? How will you detect unprofitable things without correct costing? To get the most out of your investment, keep your menu prices under control—it's plain sense.
            </p>
            <p>
            We have mastered the skill of creating menu prices that stand out in a competitive market and encourage customers to spend exactly as you want. Allow us to assist you in increasing your average bill by fine-tuning your menu, perfecting dishes, and applying effective pricing techniques.
            </p>
            <p>
            How much should I price this menu item? Is it bringing in enough profit? How are my product costs affecting my bottom line? Boost Your Profits!
            </p>
          </div>
        </div>
      </div>
      <div className="p-90">
        <div className="container py-md-4 ">
          <div className="d-md-flex justify-content-between align-items-end">
            <div className="text-center about-section position-relative mx-auto">
              <h5>Crafting Menus Inspired by Our Culinary Experts</h5>
              <h1>How can we assist you with menu development?</h1>
            </div>
          </div>  
          <div className="row g-3 mt-4 marketing">
            <div className="col-4 ">
              <div className="ck-testimonial-box text-center p-4 marketing-card">
                <i className="bi bi-trophy-fill"></i>
                <p className="mb-0">
                Take a close look at your menu, including your competitors', and consider the current market and product availability.
                </p>
              </div>
            </div>
            <div className="col-4 "> 
              <div className="ck-testimonial-box text-center p-4 marketing-card">
                <i className="bi bi-person-fill-gear"></i>
                <p className="mb-0">
                Give guidance on which items to refresh, remove, and spotlight to better cater to your needs. 
                </p>
              </div>
            </div>
            <div className="col-4 ">
              <div className="ck-testimonial-box text-center p-4 marketing-card">
                <i className="bi bi-code-square"></i>
                <p className="mb-0">
                Introduce new concepts to the table while maintaining our focus on creating a menu that increases earnings.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <CkForm toggleLoading={toggleLoading}/>
      <Footer/>
    </div>
  );
};

export default MenuPlanning;
