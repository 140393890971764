import React, { useState } from "react";
import Navbar from "../../components/common/Navbar";
import "../../styles/Services.css";
import Footer from "../../components/common/Footer";
import CkForm from "../../components/common/CkForm";
import websited1 from "../../assets/Images/digital-development/websited1.avif";
import brand1 from "../../assets/Images/brand1.avif"
import { Link } from "react-router-dom";
import { Config } from "../../config";
import Loader from "../../components/common/Loader";
const BrandDevelopment = () => {
  const [loading, setLoading] = useState(false);
   // Function to toggle loading state
 const toggleLoading = (status) => {
  setLoading(status);
};  
  return (
    <div>
      {loading && <Loader/>}
      <Navbar />
      <div className="  video-container-website">
        <img src={websited1} className="video-background"></img>
        <div className="d-flex">
          <div className="start-up-banner  banner-position right container-box ">
            <div data-aos="fade-left" className="">
              <h1>Restaurant Branding & Brand Development</h1>
              <p>
              Building an iconic brand is important for a restaurant's success. In today's world, it is crucial for restaurants to provide clients something unique and memorable. CKC specializes in building, developing, and positioning brands that resonate with your target market, hence improving every dining experience.
              </p>
              <p>
              We work with you to develop the unique mood of your restaurant, ensuring that it reflects your soul and resonates with your customers.
              </p>
              <div className="d-flex justify-content-end highlight-txt-div">
                <h3 className="">Turn Your Restaurant Into A BRAND </h3>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="break-container p-90">
        <div class="break-container-left" data-aos="fade-right">
          <h1>Social Media: The Fresh Face of Tech Innovation</h1>
          <p>
          We'll improve your existing pages to fully leverage LinkedIn, Facebook, and Instagram to grow your brand. If you haven't already created profiles on these major networking platforms, we can help you do so.
          </p>
          <div className="">  
          <Link to={`${Config.appUrl}/contact`}>
          <a className="btn btn-purple mt-2">
              Contact Us <i className="bi bi-arrow-right"></i>
              
            </a>
          </Link>
          </div>
        </div>
        <div class="break-container-right" data-aos="fade-left">
          <img src={brand1} />
        </div>
      </div>
      <CkForm toggleLoading={toggleLoading}/>
      <Footer />
    </div>
  );
};

export default BrandDevelopment;
