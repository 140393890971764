import React, { useState } from "react";
import ckform1 from "../../assets/Images/ckform1.jpg";
import axios from "axios";
import Swal from "sweetalert2";
import "../../styles/Aboutus.css";
import { Config } from "../../config";
import Loader from "./Loader";
const CkForm = ({ onSubmit, toggleLoading }) => {
  const [formdata, setFormdata] = useState({
    name: "",
    email: "",
    phone: "",
    subject: "",
    message: "",
  });
  const [errors, setErrors] = useState({});
  const [loading, setLoading] = useState(false);
  const formData = new FormData();
  formData.append("name", "value1");
  formData.append("email", "value2");
  formData.append("phone", "value2");
  formData.append("subject", "value2");
  formData.append("message", "value2");
  const handleChange = (e) => {
    setFormdata({
      ...formdata,
      [e.target.name]: e.target.value,
    });
    // Remove error message when correcting input
    setErrors({});
  };
  // Function to handle form submission
  const handleSubmit = (e) => {
    e.preventDefault();
    // Activate loader
    toggleLoading(true);
    // Validate form fields
    const validationErrors = validateForm(formdata);
    if (Object.keys(validationErrors).length === 0) {
      axios
        .post(`${Config.apiUrl}/contactUs`, formdata)
        .then((response) => {
          console.log("Form data posted successfully:", response.data);
          // Clear form data after successful submission
          setFormdata({
            name: "",
            email: "",
            phone: "",
            subject: "",
            message: "",
          });
          Swal.fire({
            title: "",
            text: "Your Data Submitted Successfully. ",
            icon: "success",
          });

          // Deactivate loader
          toggleLoading(false);
        })
        .catch((error) => {
          console.error("Error posting form data:", error);
          // Deactivate loader
          toggleLoading(false);
          Swal.fire({
            title: "",
            text: "Error! While Submitting form data",
            icon: "error",
          });
        });
    } else {
      setErrors(validationErrors);
      toggleLoading(false);
    }
  };

  // Function to validate form fields
  const validateForm = (formData) => {
    let errors = {};
    if (!formData.name.trim()) {
      errors.name = "Name is required";
    }
    if (!formData.email.trim()) {
      errors.email = "Email is required";
    } else if (!/\S+@\S+\.\S+/.test(formData.email)) {
      errors.email = "Email address is invalid";
    }
    if (!formData.phone.trim()) {
      errors.phone = "Phone number is required";
    } else if (!/^\d{10}$/.test(formData.phone)) {
      errors.phone = "Please Enter Valid Phone number ";
    }
    if (!formData.subject.trim()) {
      errors.subject = "Subject is required";
    }
    if (!formData.message.trim()) {
      errors.message = "Message is required";
    }
    return errors;
  };

  return (
    <div>
      <div className="" style={{ paddingTop: "4px" }}>
        <div className="row align-items-center contact-form">
          <div className="col-5 d-flex justify-content-center contact-form2">
            <div className=" col-10">
              <h2 className="head-text text-center mb-3">
                Get Free 30-Minute Consultation.
              </h2>
              <form className="row" onSubmit={handleSubmit}>
                {/* Input fields */}
                <div className="bd-contact-form col-6 ">
                  <label>Name</label>
                  <input
                    type="text"
                    placeholder="Enter Full Name"
                    name="name"
                    onChange={handleChange}
                    value={formdata.name}
                    className="input-form"
                  />
                  {errors.name && (
                    <span className="text-danger error-msg">{errors.name}</span>
                  )}
                </div>
                <div className="bd-contact-form col-6 ">
                  <label>Email Address</label>
                  <input
                    type="email"
                    placeholder="Enter Email"
                    name="email"
                    value={formdata.email}
                    onChange={handleChange}
                  />
                  {errors.email && (
                    <span className="text-danger error-msg">
                      {errors.email}
                    </span>
                  )}
                </div>
                <div className="bd-contact-form col-6">
                  <label>Phone Number</label>
                  <input
                    type="text"
                    placeholder="Enter Phone Number"
                    name="phone"
                    value={formdata.phone}
                    onChange={handleChange}
                  />
                  {errors.phone && (
                    <span className="text-danger error-msg">
                      {errors.phone}
                    </span>
                  )}
                </div>
                <div className="bd-contact-form col-6">
                  <label>Subject</label>
                  <input
                    type="text"
                    placeholder="Enter Subject"
                    name="subject"
                    value={formdata.subject}
                    onChange={handleChange}
                  />
                  {errors.subject && (
                    <span className="text-danger error-msg">
                      {errors.subject}
                    </span>
                  )}
                </div>
                <div className="bd-contact-form">
                  <label>Message</label>
                  <textarea
                    type="text"
                    placeholder="Enter Your Message"
                    value={formdata.message}
                    onChange={handleChange}
                    name="message"
                  />
                  {errors.message && (
                    <span className="text-danger">{errors.message}</span>
                  )}
                </div>
                {/* Submit button */}
                <div className="text-center pb-3">
                  <button type="submit" className="btn btn-purple mt-2">
                    Book Consultation <i className="bi bi-arrow-right"></i>
                  </button>
                </div>
              </form>
            </div>
          </div>
          <div className="col-7 p-0">
            <div className="position-relative contact-form-txtsection">
              <img src={ckform1} height="" alt="Contact" className="w-100" />
              <div className="bg-overlay"></div>
              <div
                className="accord-section contact-post col-6 m-auto"
                data-aos="fade-left"
                style={{ paddingTop: "10px" }}
              >
                <h1>
                  We'll assist you in creating a strategic business plan for
                  your restaurant{" "}
                </h1>
                <p className="text-white">
                  At CK Consultings, we're here to help you create a strategic
                  restaurant business plan that not only maps the course for
                  your restaurant's future but also serves as a financial guide
                  for the business. We'll walk you through the complexity of
                  your designed restaurant venture, ensuring you're
                  well-prepared for the path ahead. Our tailored business plans
                  not only differentiate you from the competition, but they also
                  act as vital tools for reviewing and fine-tuning your business
                  approach.
                </p>
                <p className="text-white">
                  In short, we help you in creating a strategy to meet your
                  restaurant's financial goals and fostering consistent,
                  long-term growth. Our Business Plan is the foundation for
                  converting your culinary dreams into real-world successes.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CkForm;
