import React, { useEffect, useState } from "react";
import Navbar from "../../../components/common/Navbar";
import Footer from "../../../components/common/Footer";
import "../../../styles/Aboutus.css";
import websited1 from "../../../assets/Images/digital-development/websited1.avif";
import Loader from "../../../components/common/Loader";

const PrivacyPolicy = () => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    window.scrollTo(0, 1);
  }, []);

  // Function to toggle loading state
  const toggleLoading = (status) => {
    setLoading(status);
  };
  return (
    <div>
      {loading && <Loader/>}
      <Navbar />
      <div className=" video-container">
        <img src={websited1} className="video-background cover"></img>
        <div>
          <div className="start-up-banner  banner-position right container-box">
            <div data-aos="fade-left" className="">
              <h1 style={{overflow:"hidden"}}>
              Privacy Policy
              </h1>
              <p>
              CK Consultings is committed to protecting the privacy and security of your personal information. This Privacy Policy describes how we collect, use, disclose, and protect the personal information of individuals who visit our website or use our services.  
              </p>
              
            </div>
            <div className="d-flex justify-content-end highlight-txt-div">
              <h3 className="">
              Privacy Policy- Ckconsultings.com
                 </h3>
            </div>
          </div>
          
        </div>
      </div>
      <div className="container p-90 terms-container">
            <h1>Privacy Policy:</h1>
            <h6 className="mt-5">Information We Collect: </h6>
            <p>When you visit our website or interact with us, we may collect personal information that you voluntarily provide to us, such as your name, email address, phone number, or other contact details. We may also collect information automatically through cookies and similar tracking technologies, such as your IP address, browser type, and operating system.</p>
            <h6>How We Use Your Information :</h6>
            <p>We may use the information we collect for various purposes, including: </p>
            <ul className="terms-li">
                
                <li>Providing and improving our services </li>
                <li>Communicating with you </li>
                <li>Responding to your inquiries</li>
                <li>Personalizing your experience </li>
                <li>Analyzing website usage and trends </li>
                <li>Complying with legal obligations </li>
            </ul>
            <h6>Information Sharing :</h6>
            <p>We may share your personal information with third parties who provide services on our behalf or as required by law. We do not sell or rent your personal information to third parties for marketing purposes.</p>
            
            <h6>Data Security:</h6>
            <p>
            We implement appropriate technical and organizational measures to protect your personal information against unauthorized access, disclosure, alteration, or destruction. 
            </p>
            <h6>Your Choices : </h6>
            <p>
            You may choose not to provide certain personal information, but this may limit your ability to access certain features of our website or services. You can also opt-out of receiving marketing communications from us at any time.
            </p>
            <h6>Links to Third-Party Websites : </h6>
            <p>
            Our website may contain links to third-party websites that are not operated by us. We are not responsible for the privacy practices or content of these websites. We encourage you to review the privacy policies of these third parties before providing any personal information.
            </p>
            <h6>Updates to this Privacy Policy:  </h6>
            <p>
            We may update this Privacy Policy from time to time to reflect changes in our practices or applicable laws. We will notify you of any material changes by posting the updated policy on our website. 
            </p>
            <h6>Contact Us :  </h6>
            <p>
            If you have any questions or concerns about our Privacy Policy or the handling of your personal information, please contact us at info@ckconsultings.com.
            </p>
           <p>
           By using our website or services, you consent to the collection, use, and disclosure of your personal information as described in this Privacy Policy.
           </p>
          </div>
     
      
      <Footer />
    </div>
  );
};

export default PrivacyPolicy;
