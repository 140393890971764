import React, { useEffect, useState } from "react";
import "../../../styles/RestaurantOperations/ExpertAdvice.css";
import Navbar from "../../../components/common/Navbar";
import Footer from "../../../components/common/Footer";
import CkForm from "../../../components/common/CkForm"
import websited1 from "../../../assets/Images/digital-development/websited1.avif"
import websited2 from "../../../assets/Images/digital-development/websited2.avif"
import Loader from "../../../components/common/Loader";
const ExpertAdvice = () => {
  const[loading,setLoading]=useState(false)
  useEffect(()=>window.scrollTo(0,1),[])
    // Function to toggle loading state
 const toggleLoading = (status) => {
  setLoading(status);
};  
  return (
    <div>
      {loading && <Loader/>}
      <Navbar />
      <div className=" video-container-expert">
        <img
          src={websited1}
          className="video-background2 cover"
        ></img>
        <div className="d-flex">
          <div className="img-div " data-aos="zoom-in">
            <img src={websited2} />
          </div>
          <div className="start-up-banner  banner-position2 right container-box">
            <div data-aos="fade-left" className=" ">
              <h1 style={{overflow:"hidden"}}>Evaluating key expenses </h1>

              <div className="  highlight-txt-div d-flex expert-advice-subheading">
                <h3>CKC Expert Advice</h3>
              </div>
              <p>Some of our customers have the knowledge and the time to administer their websites, while others may not. This is where our website maintenance plans come in. We're here to help you with regular updates and keep your website secure. Whether you need us occasionally or on a monthly basis, we're here to help you every step of the way.</p>
              <div className="d-flex  highlight-txt-div justify-content-end">
                <h3>Relax knowing we've got your website covered with security, backups, and Google compliance!</h3>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="p-90">
        <div className="container py-md-4 ">
          <div className="d-md-flex justify-content-between align-items-end">
            <div className="text-center about-section position-relative mx-auto">
              <h5>Costs Evaluation</h5>
              <h1>Have a Look at it</h1>
            </div>
          </div>
          <div className="row g-3 mt-4 marketing">
            <div className="col-lg-4">
              <div className="ck-testimonial-box text-center p-4">
              <i class="bi bi-telephone-fill"></i>
                <p className="mb-0">
                Operations
                </p>
              </div>
            </div>
            <div className="col-lg-4"> 
              <div className="ck-testimonial-box text-center p-4">
              <i class="bi bi-journals"></i>
                <p className="mb-0">
                Systems and Controls
                </p>
              </div>
            </div>
            <div className="col-lg-4">
              <div className="ck-testimonial-box text-center p-4">
                <i className="bi bi-currency-dollar"></i>
                <p className="mb-0">
                Prime Cost Solutions
                </p>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="ck-testimonial-box text-center p-4">
                <i className="bi bi-list"></i>
                <p className="mb-0">
                Menu Engineering
                </p>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="ck-testimonial-box text-center p-4">
                <i className="bi  bi-arrow-return-left"></i>
                <p className="mb-0">
                ROE
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    
      <CkForm toggleLoading={toggleLoading}/>
      <Footer/>
    </div>
  );
};

export default ExpertAdvice;
