import React, { useEffect, useState } from "react";
import "../../../styles/DigitalDevelopment/SocialMediaMarketing.css";
import Navbar from "../../../components/common/Navbar";
import Footer from "../../../components/common/Footer";
import CkForm from "../../../components/common/CkForm";
import websited1 from "../../../assets/Images/digital-development/websited1.avif"
import websited2 from "../../../assets/Images/digital-development/websited2.avif"
import social1 from "../../../assets/Images/social-media-marketing/social1.avif"
import Loader from "../../../components/common/Loader";

const SocialMediaMarketing = () => {
  const[loading,setLoading]=useState(false)
  useEffect(()=>window.scrollTo(0,1),[])
    // Function to toggle loading state
 const toggleLoading = (status) => {
  setLoading(status);
};  
  return (
    <div>
      {loading && <Loader/>}
      <Navbar/>
      <div className=" video-container-socialmedia">
        <img
          src={websited1}
          className="video-background2"
        ></img>
        <div className="d-flex">
          <div className="img-div " data-aos="zoom-in">
            <img src={websited2} />
          </div>
          <div className="start-up-banner  banner-position2 banner-position6 right container-box">
            <div data-aos="fade-left" className="">
              <h1 style={{overflow:"hidden"}}>SMM is essential for all businesses, From small or large</h1>
              <p>
              Engaging with customers through social media platforms and websites is how we spread the word about our product or service.
              </p>

              <div className="d-flex justify-content-end highlight-txt-div">
                <h3 className="">
                Building hype on social media has never been more crucial{" "}
                </h3>
              </div>
              <p>
              CKC has collaborated with Creative Agency to increase your company's web presence by displaying your menu to a captivated audience. Make sure your brand gets the attention it deserves.
              </p>
            </div>
          </div>
        </div>
      </div>
      <br></br>
      <div className="d-flex p-90">
       
        <div className="col-12 d-flex gap-5 video-container2-inn">
          <div className="col-4 video-container2-inn-left ">
            <h2>Social Media Packages</h2>
            <p>
            Our social media packages for small businesses are designed to help you realize the full potential of SMM, leveraging the multiple benefits that social networks provide to enterprises. We recognize the importance of remaining'social' in today's corporate landscape, which is why we've customized our packages to achieve meaningful outcomes at a reasonable price.{" "}
            </p>
            <h3>Why should you use our SMM packages?</h3>
            <p>
            Our packages are ideal for small and medium-sized businesses seeking to increase their social media presence with little effort and spend. We understand how valuable your time is, thus our role is to manage your online reputation.{" "}
            </p>
            <h3>What Can You Expect from Our Social Media Packages?</h3>
            <p>
            We guarantee that all of our social media packages will deliver minimum monthly results. There are no startup costs or long-term commitments, and you can cancel at any time with no penalties.{" "}
            </p>
          <div style={{height:"300px"}} data-aos="zoom-in" className="pt-2">
          <img className="w-100 h-100 pb-1 social-img" src={social1} />
          </div>
          </div>
          <div className=" col-lg-8 d-flex social-side" >
          
            <div className="row g-3 mt-0 d-flex flex-column  marketing">
            
              <div className="col-lg-8">
              <div><h3 className="font-styled font-styled-social">Our social media packages come with the following perks</h3></div>
                <div className="ck-testimonial-box text-center p-2 " data-aos="fade-left">
                  <i className="bi bi-people-fill new-icon"></i>
                  <h4 className="">Superior Postings</h4>
                  <p className="mb-0">We will craft social media posts using the content from your website or related to it to boost interaction and connect with your desired audience.</p>
                </div>
              </div>
              <div className="col-lg-8">
                <div className="ck-testimonial-box text-center p-2" data-aos="fade-left">
                  <i className="bi bi-pin-map-fill"></i>
                  <h4 className="">Discovering New Followers</h4>
                  <p className="mb-0">
                  We will discuss your business goals and plan our strategies to meet them perfectly. Our goal is to connect you with new followers that actually connect with your brand and add genuine value to it.
                  </p>
                </div>
              </div>
              <div className="col-lg-8">
                <div className="ck-testimonial-box text-center p-2" data-aos="fade-left">
                  <i className="bi bi-bar-chart-steps"></i>
                  <h4 className="">Social media Page Optimization</h4>
                  <p className="mb-0">
                  Before we begin advertising, we will optimize your social media accounts to increase exposure and visibility. At the end of each month, you'll receive a comprehensive status report that details all of the insights and advancements achieved from your campaigns.
                  </p>
                </div>
              </div> 
            </div>
          </div>
        </div>
      </div>

    <CkForm toggleLoading={toggleLoading}/>
      <Footer />
    </div>
  );
};

export default SocialMediaMarketing;
