import React, { useEffect, useState } from "react";
import Navbar from "../../../components/common/Navbar";
import Footer from "../../../components/common/Footer";
import "../../../styles/Aboutus.css";
import CkForm from "../../../components/common/CkForm";
import websited1 from "../../../assets/Images/digital-development/websited1.avif";
import about1 from "../../../assets/Images/company/about1.webp";
import Loader from "../../../components/common/Loader";

const Terms = () => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    window.scrollTo(0, 1);
  }, []);

  // Function to toggle loading state
  const toggleLoading = (status) => {
    setLoading(status);
  };
  return (
    <div>
      {loading && <Loader/>}
      <Navbar />
      <div className=" video-container">
        <img src={websited1} className="video-background cover"></img>
        <div>
          <div className="start-up-banner  banner-position right container-box">
            <div data-aos="fade-left" className="">
              <h1 style={{overflow:"hidden"}}>
              Terms & Conditions
              </h1>
              <p>
              Welcome to CK Consultings! These Terms & Conditions outline the rules and regulations for the use of our website, located at www.ckconsultings.com. 
              </p>
              <p>
              By accessing this website, we assume you accept these Terms & Conditions. Do not continue to use CK Consultings if you do not agree to all of the Terms & Conditions stated on this page. 
              </p>
            </div>
            <div className="d-flex justify-content-end highlight-txt-div">
              <h3 className="">
              Terms & Conditions- Ckconsultings.com
                 </h3>
            </div>
          </div>
          
        </div>
      </div>
      <div className="container p-90 terms-container">
            <h1>Terms & Conditions:</h1>
            <h6 className="mt-5">License: </h6>
            <p>Unless otherwise stated, CK Consultings and/or its licensors own the intellectual property rights for all material on CK Consultings. All intellectual property rights are reserved. You may access this from CK Consultings for your own personal use subjected to restrictions set in these Terms & Conditions.</p>
            <h6>Restrictions:</h6>
            <p>You are specifically restricted from all of the following:</p>
            <ul className="terms-li">
                
                <li>Publishing any CK Consultings material in any other media.</li>
                <li>Selling, sublicensing, and/or otherwise commercializing any CK Consultings material. </li>
                <li>Publicly performing and/or showing any CK Consultings material.</li>
                <li>Using this website in any way that is or may be damaging to this website. </li>
                <li>Using this website in any way that impacts user access to this website. </li>
                <li>Using this website contrary to applicable laws and regulations, or in any way may cause harm to the website, or to any person or business entity. </li>
            </ul>
            <h6>Your Content: </h6>
            <p>In these Website Standard Terms & Conditions, "Your Content" shall mean any audio, video text, images, or other material you choose to display on CK Consultings. By displaying Your Content, you grant CK Consultings a non-exclusive, worldwide irrevocable, sub-licensable license to use, reproduce, adapt, publish, translate, and distribute it in any and all media.</p>
            <p>
            Your Content must be your own and must not be invading any third-party's rights. CK Consultings reserves the right to remove any of Your Content from this website at any time without notice.
            </p>
            <h6>No warranties:</h6>
            <p>
            This website is provided "as is," with all faults, and CK Consultings expresses no representations or warranties, of any kind related to this website or the materials contained on this website. Also, nothing contained on this website shall be interpreted as advising you. 
            </p>
            <h6>Limitation of liability: </h6>
            <p>
            In no event shall CK Consultings, nor any of its officers, directors, and employees, shall be held liable for anything arising out of or in any way connected with your use of this website, whether such liability is under contract. CK Consultings, including its officers, directors, and employees shall not be held liable for any indirect, consequential, or special liability arising out of or in any way related to your use of this website.
            </p>
            <h6>Indemnification: </h6>
            <p>
            You hereby fully indemnify CK Consultings from and against any and/or all liabilities, costs, demands, causes of action, damages, and expenses arising in any way related to your breach of any of the provisions of these Terms.
            </p>
            <h6>Severability:  </h6>
            <p>
            If any provision of these Terms is found to be invalid under any applicable law, such provisions shall be deleted without affecting the remaining provisions herein. 
            </p>
            <h6>Variation of Terms:  </h6>
            <p>
            CK Consultings is permitted to revise these Terms at any time as it sees fit, and by using this website, you are expected to review these Terms regularly.
            </p>
            <h6>Assignment:  </h6>
            <p>
            CK Consultings is allowed to assign, transfer, and subcontract its rights and/or obligations under these Terms without any notification. However, you are not allowed to assign, transfer, or subcontract any of your rights and/or obligations under these Terms. 
            </p>
            <h6>Entire Agreement:  </h6>
            <p>
            These Terms constitute the entire agreement between CK Consultings and you concerning your use of this website and supersede all prior agreements and understandings.
            </p>
            <h6>Governing Law & Jurisdiction: </h6>
            <p>
            These Terms will be governed by and interpreted in accordance with the laws of the Province of Ontario, Canada, and you submit to the non-exclusive jurisdiction of the state and federal courts located in Ontario for the resolution of any disputes. </p>
            <p>

If you have any questions about these Terms & Conditions, please contact us at info@ckconsultings.com. 
            </p>
          </div>
     
      
      <Footer />
    </div>
  );
};

export default Terms;
