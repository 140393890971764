import React, { useState, useEffect } from "react";
import websited1 from "../../../assets/Images/digital-development/websited1.avif";
import ckform1 from "../../../assets/Images/ckform1.jpg";
import Navbar from "../../../components/common/Navbar";
import Footer from "../../../components/common/Footer";
import "../../../styles/Company/Contactus.css";
import axios from "axios";
import Loader from "../../../components/common/Loader";
import "../../../styles/Company/Contactus.css"
import Swal from "sweetalert2";
import CkForm from "../../../components/common/CkForm";
import { Config } from "../../../config";

const ContactUs = () => {
  const [swalProps, setSwalProps] = useState({});

  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState({});
  // Function to toggle loading state
  const toggleLoading = (status) => {
    setLoading(status);
  };
 
  useEffect(() => {
    window.scrollTo(0, 1);
  }, []);
  // console.log(data);

  return (
    <div>
      {loading && <Loader/>}

      <Navbar />
      <div className=" video-container">
        <img
          src={websited1}
          className="video-background cover"
        ></img>
        <div>
          <div className="start-up-banner  banner-position right container-box">
            <div data-aos="fade-left" className="">
              <h1>Contact us<br/> from Toronto to Halifax</h1>
              <p>
                  We are here to give you with proficient advice. At CKC, we have a team of seasoned professionals and restaurant managers who cover all part of the sector, from finance and development to operations and management.
              </p>
              <h2 className="fw-bolder system-txt last-txt">
                {" "}
                  Contact us for any solutions
              </h2>
            </div>
          </div>
        </div>
      </div>

     <div className="p-90">
     <CkForm toggleLoading={toggleLoading}/>
     </div>
      <Footer />
    </div>
  );
};

export default ContactUs;
