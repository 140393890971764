import { Axios } from "axios";
import React, { useState } from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import Loader from "./Loader";
import Swal from 'sweetalert2';
import "../../styles/Loader.css"
import { Config } from "../../config";
const ContactSection = ({contactSection, toggleLoading}) => {
    const [errors, setErrors] = useState({});
  const [loading, setLoading] = useState(false);
  const [formdata, setFormdata] = useState({
    name: "",
    email: "",
    phone: "",
    message: "",
  });

  const handleChange = (e) => {
    setFormdata({
      ...formdata,
      [e.target.name]: e.target.value,
    });
    setErrors({
      ...errors,
      [e.target.name]: "", // Clear the error message when the input changes
    });
  };

  const validateForm = () => {
    const newErrors = {};
    // Basic validation for required fields
    if (!formdata.name.trim()) {
      newErrors.name = "Name is required";
    }
    if (!formdata.email.trim()) {
      newErrors.email = "Email is required";
    } else if (!/\S+@\S+\.\S+/.test(formdata.email)) {
      newErrors.email = "Email address is invalid";
    }
    if (!formdata.phone.trim()) {
      newErrors.phone = "Phone number is required";
    } else if (!/^\d{10}$/.test(formdata.phone)) {
      newErrors.phone = "Please Enter Valid Phone number";
    }
    if (!formdata.message.trim()) {
      newErrors.message = "Message is required";
    }
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0; // Return true if there are no errors
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (!validateForm()) {
      return; // Exit if form validation fails
    }
    // Activate loader
    toggleLoading(true);
    axios
      .post(`${Config.apiUrl}/getPlan`, formdata)
      .then((response) => {
         // Deactivate loader
         toggleLoading(false);
        setFormdata({
          name: "",
          email: "",
          phone: "",
          message: "",
        });
        Swal.fire({
          title: "",
          text: "Your Data Submitted Successfully. ",
          icon: "success",
        });
      })
      .catch((error) => {
        console.error("Error posting form data:", error);
         // Deactivate loader
         toggleLoading(false);
        Swal.fire({
          title: "",
          text: "Error! While Submitting form data",
          icon: "error",
        });
      });
  };
  return (
    <div className="" >
      <div className="py-5 contact-bg" id="contact-section">
        <div className="bg-overlay"></div>
        <div className="container-box py-md-5 position-relative">
          <div className="row gap-4 justify-content-around">
            <div className="col-md-6 ps-md-5">
              <div className="cta-section">
                <h5 className="text-white">
                  {contactSection?.title}
                </h5>
                <h2 className="text-white">
                  CKC offers a free 30-minute call consultation to any food service professional.
                </h2>
              </div>
              <div className="mt-4 pt-3 pb-3">
                <a className="style-btn style-btn-3 webview" href="tel:+1 365-662-4183">
                  <span
                    style={{ paddingLeft: "18px" }}
                    className="position-relative"
                  >
                    <i className="bi bi-telephone text-dark"></i>
                  </span>
                  <div className="d-inline ps-4 position-relative">
                    Call Us : +1 365-662-4183
                  </div>
                </a>
              </div>
            </div>

            <div className="col-md-4">
              <form
                className="bd-cta-form "
                data-aos="fade-down-right"
                onSubmit={handleSubmit}
              >
                <div className="row gap-3">
                  <div className="bd-forms-inputs">
                    <input
                      placeholder="Enter Name"
                      type="text"
                      name="name"
                      value={formdata.name}
                      onChange={handleChange}
                    />
                     {errors.name && (
                      <div className="text-danger error-msg">{errors.name}</div>
                    )}
                  </div>
                  <div className="bd-forms-inputs">
                    <input
                      placeholder="Enter Email"
                      type="text"
                      name="email"
                      value={formdata.email}
                      onChange={handleChange}
                    />
                      {errors.email && (
                      <div className="text-danger error-msg">{errors.email}</div>
                    )}
                  </div>
                  <div className="bd-forms-inputs">
                    <input
                      placeholder="Enter Phone"
                      type="text"
                      name="phone"
                      value={formdata.phone}
                      onChange={handleChange}
                    />
                     {errors.phone && (
                      <div className="text-danger error-msg">{errors.phone}</div>
                    )}
                  </div>
                  <div className="bd-forms-inputs">
                    <textarea
                      placeholder="Enter Comments"
                      type="text"
                      value={formdata.message}
                      onChange={handleChange}
                      name="message"
                    />
                      {errors.message && (
                      <div className="text-danger">{errors.message}</div>
                    )}
                  </div>
                </div>
                <div className="text-center">
                  <button
                    className="btn btn-purple bg-white text-purple  mt-4"
                    type="submit"
                  >
                    Send A Message{" "}
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ContactSection;
