import React, { useEffect, useState } from "react";
import Navbar from "../../../components/common/Navbar";
import "../../../styles/DigitalDevelopment/RestaurantSEO.css";
import Footer from "../../../components/common/Footer";
import CkForm from "../../../components/common/CkForm";
import websited1 from "../../../assets/Images/digital-development/websited1.avif";
import websited2 from "../../../assets/Images/digital-development/websited2.avif";
import seo1 from "../../../assets/Images/digital-development/seo1.avif";
import Loader from "../../../components/common/Loader";
const RestaurantSEO = () => {
  const [loading, setLoading] = useState(false);
  useEffect(() => window.scrollTo(0, 1), []);
  // Function to toggle loading state
  const toggleLoading = (status) => {
    setLoading(status);
  };
  return (
    <div>
      {loading && <Loader/>}
      <Navbar />
      <div className=" video-container-seo">
        <img src={websited1} className="video-background2 cover"></img>
        <div className="d-flex">
          <div className="img-div " data-aos="zoom-in">
            <img src={websited2} className="cover"/>
          </div>
          <div className="start-up-banner  banner-position2 banner-seo right container-box">
            <div data-aos="fade-left" className="">
              <h1 style={{overflow:"hidden"}}>Restaurant SEO</h1>
              <p>The ultimate way to get noticed online</p>
              <p>
                The right type of web optimization and promotion are crucial for
                online success. We're here to help!
              </p>
              <div className="d-flex justify-content-end highlight-txt-div">
                <h3 className="">
                  Is your business be easily discovered by your customers?{" "}
                </h3>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="container p-90">
        <div className="row">
          <div className="col-lg-6 seo-txt-img" data-aos="fade-right" style={{paddingTop:"7px"}}>
            <h3 className="font-styled">Productivity Is The Key</h3>
            <p>
              Succeeding in small business, Online marketing requires more than
              merely having a website. Your business must be readily
              discoverable and appealing to customers eager to invest in your
              products and services.
            </p>
            <p>
              For beginners, understanding search engine rankings can feel like
              exploring a maze. We understand—time is money, especially for
              small business owners! CKC knows your busy schedule, this is why
              we have got you covered. Our innovative technology automates SEO
              processes, saving your time and money while providing high-quality
              online advertising solutions at a reasonable cost.
            </p>
            <p>
              Our service allows you to showcase your business while focusing on
              what you do best – running your company.
            </p>
          </div>
          <div
            className="col-lg-6 text-center seo-txt-img"
            data-aos="fade-left"
          >
            <img src={seo1} />
          </div>
        </div>
      </div>
      <div className="d-md-flex justify-content-between align-items-end">
        <div className="text-center about-section position-relative mx-auto">
          <h5>About SEO</h5>
          <h1>Take a glance</h1>
        </div>
      </div>
      <section class="d-flex justify-content-center main-sectionn">
        <div class="cardd" data-aos="zoom-in">
          <div className="ck-testimonial-box3 text-center p-4">
            <i className="bi bi-people-fill"></i>
            <h3 className="mb-0">What Is SEO?</h3>
            <p className="pt-3">
              Search engines are aimed to help customers in discovering exactly
              what they're looking for. Search Engine Optimization, means
              improving your website's visibility on search engines. It can be
              done through strategies such as creating links to your website and
              selectively adding keywords that match customer search terms.
            </p>
          </div>
        </div>
        <div class="cardd" data-aos="zoom-in">
          <div className="ck-testimonial-box3 text-center p-4">
            <i className="bi bi-bar-chart-steps"></i>
            <h3 className="mb-0">Principle of SEO</h3>
            <p className="pt-3">
              {" "}
              The results that appear on search engines are generated by both
              the amount and quality of connections to a website. The more links
              pointing to your website, the higher its ranking, making it more
              visible to your consumers. Establishing connections to your
              website is critical for the success of any business seeking for an
              online presence.
            </p>
          </div>
        </div>
        <div class="cardd" data-aos="zoom-in">
          <div className="ck-testimonial-box3 text-center p-4">
            <i className="bi bi-person-fill-gear"></i>
            <h3 className="mb-0">
              Google handles over 80 billion searches monthly
            </h3>
            <p className="pt-3">
              According to some calculations, about 99% of clicks on search
              engines come from the first ten results, which is the first page.
              Additionally, 97% of those clicks originate from the top three
              results. Essentially, if your business doesn't appear on the first
              page, there's only a 1% chance it'll catch someone's eye.
            </p>
          </div>
        </div>
        <div class="cardd" data-aos="zoom-in">
          <div className="ck-testimonial-box3 text-center p-4">
            <i className="bi bi-code-square"></i>
            <h3 className="mb-0">Boost Traffic. Boost Revenue.</h3>
            <p className="pt-3">
              Every second, over eight new internet users join up. And search
              engines are at the core of how people use the internet. A stunning
              93% of internet connections begin with a search engine. Investing
              in SEO may propel your website to the top of search engine
              results, making it easy to find by potential customers.
            </p>
          </div>
        </div>
      </section>
      <CkForm toggleLoading={toggleLoading} />
      <Footer />
    </div>
  );
};

export default RestaurantSEO;
