import React from 'react'
import "../../styles/Loader.css"
const Loader = () => {
  return (
    <div className='loader1'>
      <div class="spinner"></div>
    </div>
  )
}

export default Loader
