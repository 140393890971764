import React from 'react'
import BannerVideo from ".././assets/Images/Banner.mp4"
import { Link } from 'react-router-dom'
import { Config } from '../config'
const   MainBanner = (bannerData) => { 
    // console.log(bannerData);
    return (
        <div>
            <div className="video-container">
                <video autoPlay loop muted className="video-background">
                    <source src={BannerVideo} />
                    Your browser does not support the video tag.
                </video>
                <div>
                    <div className='banner-text home-banner'>
                        <div data-aos="zoom-in">
                            <h1 style={{width:"50%",margin:"auto",overflow:"hidden"}} className='home-banner-h1'>
                                Restaurant Solutions For Entrepreneurs
                            </h1>
                            <p>
                                Menu Development | Branding | Digital Development
                            </p>
                        </div>
                        <div className=''>
                           <Link to={`${Config.appUrl}/contact`}> <a className='btn btn-purple mt-4'>
                            Book Consultation{" "}
                            <i className='bi bi-arrow-right'></i></a></Link>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default MainBanner