import "bootstrap/dist/css/bootstrap.css";
import "bootstrap/dist/js/bootstrap.bundle.min.js";
import "bootstrap-icons/font/bootstrap-icons.css";
import { BrowserRouter as Router, Route,Routes} from "react-router-dom";
import index from "./pages/Home";
import "./styles/global.css"
import "./styles/Services.css"
import { Config } from "./config";
import StartUp from "./pages/Services/StartUp";
import MenuDevelopment from "./pages/Services/MenuDevelopment";
import { useEffect } from "react";
import Aos from "aos";
import WebsiteDevelopment from "./pages/Services/DigitalDevelopment/WebsiteDevelopment";
import BrandDevelopment from "./pages/Services/BrandDevelopment";
import RestaurantPerformance from "./pages/Services/RestaurantOperation/RestaurantPerformance";
import Aboutus from "./pages/Services/Company/Aboutus";
import OurTeam from "./pages/Services/Company/OurTeam";
import Blog from "./pages/Services/Company/Blog";
import Gallery from "./pages/Services/Company/Gallery";
import WebsiteMaintenance from "./pages/Services/DigitalDevelopment/WebsiteMaintenance";
import SocialMediaMarketing from "./pages/Services/DigitalDevelopment/SocialMediaMarketing";
import RestaurantSEO from "./pages/Services/DigitalDevelopment/RestaurantSEO";
import OnlineOrdering from "./pages/Services/DigitalDevelopment/OnlineOrdering";
import MenuPlanning from "./pages/Services/RestaurantOperation/MenuPlanning";
import RestaurantSystems from "./pages/Services/RestaurantOperation/RestaurantSystems";
import ERCMetrics from "./pages/Services/RestaurantOperation/ERCMetrics";
import ERCStrategic from "./pages/Services/RestaurantOperation/ERCStrategic";
import ExpertAdvice from "./pages/Services/RestaurantOperation/ExpertAdvice";
import Testimonial from "./pages/Services/Company/Testimonial";
import ContactUs from "./pages/Services/Company/ContactUs";
import BlogInner from "./pages/Services/Company/Blog-inner-pages/BlogInner";
import Terms from "./pages/Services/Company/Terms";
import PrivacyPolicy from "./pages/Services/Company/PrivacyPolicy";

function App() {

  useEffect(() => {
    Aos.init({
        offset: 100, // offset (in px) from the original trigger point
        duration: 500, // values from 0 to 3000, with step 50ms
        easing: "ease-out",
        once: false,
    });
}, []);


  return (
    <div className="App">
      <Router>
        <Routes>
          <Route path={`${Config.appUrl}`} Component={index} />
          <Route path={`${Config.appUrl}/start-ups`} Component={StartUp} />
          <Route path={`${Config.appUrl}/menu-development-branding`} Component={MenuDevelopment} />
          <Route path={`${Config.appUrl}/brand`} Component={BrandDevelopment} />
          <Route path={`${Config.appUrl}/website-development`} Component={WebsiteDevelopment} />
          <Route path={`${Config.appUrl}/website-maintenance`} Component={WebsiteMaintenance} />
          <Route path={`${Config.appUrl}/social-media-marketing`} Component={SocialMediaMarketing} />
          <Route path={`${Config.appUrl}/seo`} Component={RestaurantSEO} />
          <Route path={`${Config.appUrl}/online-ordering`} Component={OnlineOrdering} />
          <Route path={`${Config.appUrl}/performance`} Component={RestaurantPerformance} />
          <Route path={`${Config.appUrl}/menu-planning`} Component={MenuPlanning} />
          <Route path={`${Config.appUrl}/restaurant-systems`} Component={RestaurantSystems} />
          <Route path={`${Config.appUrl}/ckc-metrics`} Component={ERCMetrics} />
          <Route path={`${Config.appUrl}/ckc-strategic`} Component={ERCStrategic} />
          <Route path={`${Config.appUrl}/expert-advice`} Component={ExpertAdvice} />
          <Route path={`${Config.appUrl}/about`} Component={Aboutus} />
          <Route path={`${Config.appUrl}/terms`} Component={Terms} />
          <Route path={`${Config.appUrl}/privacy-policy`} Component={PrivacyPolicy} />
          <Route path={`${Config.appUrl}/ourteam`} Component={OurTeam} />
          <Route path={`${Config.appUrl}/blog`} Component={Blog} />
          {/* blog inner pages */}
         <Route path={`${Config.appUrl}/blog/:slug`} Component={BlogInner} />
          {/* <Route path={`${Config.appUrl}/restaurant-revenue`} Component={Restaurant_revenue} />
          <Route path={`${Config.appUrl}/going-cashless`} Component={GoingCashless} />
          <Route path={`${Config.appUrl}/rising-menu-price`} Component={Rising_menu_price} />
          <Route path={`${Config.appUrl}/future-brand`} Component={FutureBrand} />
          <Route path={`${Config.appUrl}/franchise-development`} Component={FranchiseDevelopment} />
          <Route path={`${Config.appUrl}/bussiness-ready`} Component={BussinessReady} />
          <Route path={`${Config.appUrl}/perks`} Component={Perks} />
          <Route path={`${Config.appUrl}/franchise-marketing`} Component={FranchiseMarketing} /> */}
          <Route path={`${Config.appUrl}/testimonial`} Component={Testimonial} />
          {/* <Route path={`${Config.appUrl}/gallery`} Component={Gallery} /> */}
          <Route path={`${Config.appUrl}/contact`} Component={ContactUs} />
        </Routes>
      </Router>
      </div>
  )
}
export default App;