import React from "react";
import { Config } from "../../config";
import { Link } from "react-router-dom";
import servicebanner from "../../assets/Images/startup/servicebanner.jpg"
const ServiceBanner = (introSection) => {
  return (
    <div>
      <div className="video-container-website">
        <img
          src={servicebanner}
          className="video-background"
        ></img>
        <div>
          <div className="start-up-banner  banner-position  container-box">
            <div data-aos="fade-up" className="">
              <h1 style={{overflow:"hidden"}}>
               
                Opening a new restaurant is a challenging task.
              </h1>
              <p>
                Discovering the perfect spot, concept, ambiance, cuisine, team,
                coaching, and operational checks create the recipe for a
                successful restaurant. However, remember that having the right
                allies and suppliers is what truly brings your concept to
                reality.
              </p>
              <p>
                At CKC, we don't just provide skills but we aim to help you
                ignite your full potential. Regardless of you are in the project
                process, we look forward to working with you as dedicated
                partners in this hard, yet tremendously satisfying industry.
                Here's an overview of the startup services we provide:
              </p>
            </div>
            <div className="">
              <Link to={`${Config.appUrl}/contact`}>
                {" "}
                <a className="btn btn-purple mt-2">
                  Book Consultation{" "}
                  <i className="bi bi-arrow-right"></i>
                </a>
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ServiceBanner;
