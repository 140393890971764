import React, { useEffect, useState } from "react";
// import "../../../../styles/Company/Blog.css"
import "../../../../styles/Company/Blog-inner-pages/BlogInner.css";
import Navbar from "../../../../components/common/Navbar";
import Footer from "../../../../components/common/Footer";
import websited1 from "../../../../assets/Images/digital-development/websited1.avif";
import axios from "axios";
import Loader from "../../../../components/common/Loader";
import { Link, useParams } from "react-router-dom";
import { Config } from "../../../../config";
const BlogInner = () => {
  const [loading, setLoading] = useState(false);
  const[data,setData] = useState({})
  const slug = useParams();
  useEffect(() => {
    window.scrollTo(0,1);
    fun(slug);
    ; // Fetch data initially
  }, [slug]);
 function fun(slug){
  setLoading(true);
    return axios
    .get(`${Config.apiUrl}/blogDetail/${slug.slug}`)
      .then((response) => {
        setLoading(false);
        setData(response.data.data);
        // console.log("data success", response.data);
      })
      .catch((error) => {
        console.error("Error :", error);
        setLoading(false);
      });
 }
  // console.log(blogData);
  // console.log(data);
  return (
    <div className="blog-detail1">
       {loading && <Loader/>}
        <Navbar />
      <div className="video-container-blog-inn">
        <img src={websited1} className="video-background-blog-inn"></img>
        <div className="d-flex">
          <div className="img-div " data-aos="zoom-in">
          </div>
          <div className="start-up-banner  banner-position right container-box pt-3">
            <div data-aos="fade-left" className="pt-5">
              <h1>Blog Details</h1>

              <div className="d-flex justify-content-end highlight-txt-div">
                <h3>
                {data.title}
                </h3>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="">
        <div className="">
          <div className="container col-lg-12 d-flex gap-5 blog-container p-90">
            <div className="col-lg-9 blog-inn-left2">
              <div className="methodology-top">
                <img src={data.main_image} />
              </div>
              <div className="p-4">
                <h1 className="">{data.title}</h1>
                <p>{data.content}</p>
                <p>{data.content}</p>
                <p>{data.content}</p>
                <p>{data.content}</p>
                {/* <p>
                  <ul>
                    <li>
                      Write Out All Menu Items – Unless you are sure about what
                      things you want on your menu, it is not possible to design
                      one. You need to make a list of the meals that you
                      specialize in and would like to offer to our customers.
                    </li>
                    <li>
                      Categorize Menu Items – You can sort all your menu items
                      into categories, which would help you in creating proper
                      sections in your menus.
                    </li>
                    <li>
                      Set Menu Prices – Deciding upon the prices is another
                      crucial aspect.
                    </li>
                    <li>
                      Create Menu Descriptions – Your descriptions should be
                      creative enough to make it possible for your customers to
                      visualize your dishes.
                    </li>
                    <li>
                      Deciding On a Menu Color Scheme – Color schemes play a
                      vital role in a restaurant business. Neither going
                      overboard is recommended, nor going with boring layouts is
                      recommended. It is important to find the right balance.{" "}
                    </li>
                    <li>
                      Designing Restaurant Menu – Your menu design should be
                      captivating enough to capture the audience’s attention.
                    </li>
                    <li>
                      Restaurant Menu Photos – Photos are another important
                      aspect to compel potential consumers to order.
                    </li>
                    <li>
                      Choosing Menu Fonts, Spacing, And Composition – Easy to
                      read and elegant fonts often look great on menus.{" "}
                    </li>
                    <li>
                      Select The Final Menu Layout – Now comes the final layout
                      of your dream restaurant menu.
                    </li>
                    <li>
                      Proofread And Print Menu– Before you get your menus
                      printed, make sure you proofread them.
                    </li>
                  </ul>
                </p> */}
              </div>
            </div>
            <div className="col-lg-3  blog-bottom">
              <div className="blog-right">
                <div class=" px-3 ">
                    <h4  >Recent Posts</h4>   
                    {/* <div className="blog-right-txt ">Menu Methodology</div>
                    <div className="blog-right-txt">Increasing Restaurant Revenues and Profitability</div>
                    <div className="blog-right-txt">Going Cashless</div>
                    <div className="blog-right-txt">Become a Brand for the Future</div>
                    <div className="blog-right-txt">A Look At Today's Rising Menu Price</div> */}
                    {data?.blog_data?.slice(0,5).map((item,index)=>(
                       <div className="blog-right-txt" key={index}>
                           <Link className="blog-links"  onClick={() => fun(item.slug)}   to={`${Config.appUrl}/blog/${item.slug}` }><div class="text-body-secondary">{item.title}</div></Link>
                      
                       </div>                    
                    ))}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default BlogInner;
