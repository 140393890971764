import React, { useEffect, useState } from "react";
import Navbar from "../../../components/common/Navbar";
import Footer from "../../../components/common/Footer";
import "../../../styles/Aboutus.css";
import CkForm from "../../../components/common/CkForm";
import websited1 from "../../../assets/Images/digital-development/websited1.avif";
import about1 from "../../../assets/Images/company/about1.webp";
import Loader from "../../../components/common/Loader";

const Aboutus = () => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    window.scrollTo(0, 1);
  }, []);

  // Function to toggle loading state
  const toggleLoading = (status) => {
    setLoading(status);
  };
  return (
    <div>
      {loading && <Loader/>}
      <Navbar />
      <div className=" video-container">
        <img src={websited1} className="video-background cover"></img>
        <div>
          <div className="start-up-banner  banner-position right container-box">
            <div data-aos="fade-left" className="">
              <h1 style={{overflow:"hidden"}}>
                Who we are?
              </h1>
              <p>
                CKC brings together a group of experienced professionals and
                restaurant managers who cover all aspects of the industry, from
                finances and development to operations and management.
              </p>
              <p>
                From devising and executing restaurant business plans to
                conceptualizing inventive ideas and menus, we possess extensive
                expertise in the restaurant industry and have effectively aided
                both independent ventures and franchise establishments.
              </p>
            </div>
            <div className="d-flex justify-content-end highlight-txt-div">
              <h3 className="">
                Masters of Restaurant Operations
                 </h3>
            </div>
          </div>
        </div>
      </div>
      <div className="break-container2 p-90">
        <div class="break-container2-left" data-aos="fade-right">
          <h1>
            What We Do
            </h1>
          <h3>
            Understand Your Business and Offer Solutions
            </h3>
          <p>
          We're here to help you reduce operating costs by advising you on how to source and manage items more efficiently. In addition, we provide technical solutions to help you cut your workforce costs.
          </p>
          <p>
          We deeply examine your organization, find areas of concern, propose solutions, and make recommendations for improvement. With a combination of resources and skilled personnel with knowledge in their respective disciplines, we're here to help you with every aspect of your business.
          </p>
        </div>
        <div class="break-container2-right2" data-aos="fade-left">
          <img 
          src={about1}
          className="cover"
          />
        </div>
      </div>
      <div className="">
        <div className="container ">
          <div className="d-md-flex justify-content-between align-items-end">
            <div className="text-center about-section position-relative mx-auto">
              <h5>
              Whom we help
              </h5>
              <h1>
                Let us guide you on enhancing your efficiency.
                </h1>
            </div>
          </div>
          <div className="row g-3 mt-4 marketing">
            <div className="col-3">
              <div className="ck-testimonial-box2 text-center p-4"  data-aos="zoom-in">
                <i class="bi bi-building-fill"></i>
                <p className="mb-0">Independent Restaurants</p>
              </div>
            </div>
            <div className="col-3">
              <div className="ck-testimonial-box2 text-center p-4"  data-aos="zoom-in">
                <i class="bi bi-bezier"></i>
                <p className="mb-0">Multi-Unit Operators</p>
              </div>
            </div>
            <div className="col-3">
              <div className="ck-testimonial-box2 text-center p-4"  data-aos="zoom-in">
                <i class="bi bi-buildings-fill"></i>
                <p className="mb-0">Existing Restaurants</p>
              </div>
            </div>
            <div className="col-3">
              <div className="ck-testimonial-box2 text-center p-4"  data-aos="zoom-in">
                <i class="bi bi-building-fill"></i>
                <p className="mb-0">New Restaurants</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="p-90">
        <div className="container p ">
          <div className="d-md-flex justify-content-between align-items-end">
            <div className="text-center about-section position-relative mx-auto">
              <h5>
                How we help
                </h5>
              <h1>
                Have a look
                </h1>
            </div>
          </div>
          <div className="row g-3 mt-4 marketing">
            <div className="col-3">
              <div className="ck-testimonial-box2 text-center p-4"  data-aos="zoom-in">
              <i class="bi bi-search"></i>
                <p className="mb-0">Explore & Identify Needs</p>
              </div>
            </div>
            <div className="col-3">
              <div className="ck-testimonial-box2 text-center p-4"  data-aos="zoom-in">
              <i class="bi bi-clipboard-data"></i>
                <p className="mb-0">Evaluate, Analyze, & Strategize</p>
              </div>
            </div>
            <div className="col-3">
              <div className="ck-testimonial-box2 text-center p-4"  data-aos="zoom-in">
              <i class="bi bi-pie-chart-fill"></i>
                <p className="mb-0">Execute & Implement Solutions</p>
              </div>
            </div>
            <div className="col-3">
              <div className="ck-testimonial-box2 text-center p-4"  data-aos="zoom-in">
              <i class="bi bi-bar-chart"></i>
                <p className="mb-0">Drive Enterprise Value</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <CkForm toggleLoading={toggleLoading} />
      <Footer />
    </div>
  );
};

export default Aboutus;
