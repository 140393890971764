import React, { useEffect, useRef, useState } from "react";
import Navbar from "../../components/common/Navbar";
import Footer from "../../components/common/Footer";
import CkForm from "../../components/common/CkForm";
import menud1 from "../../assets/Images/menu-development/menud1.jpg";
import menud2 from "../../assets/Images/Index/index1.jpg";
import menud3 from "../../assets/Images/Index/index2.jpg";
import menud6 from "../../assets/Images/menu-development/menud6.avif";
import menud7 from "../../assets/Images/menu-development/menud7.avif";
import { Link } from "react-router-dom";
import { Config } from "../../config";
import Loader from "../../components/common/Loader";
const MenuDevelopment = () => {
  const [loading, setLoading] = useState(false);
  const section1Ref = useRef(null);
  const section2Ref = useRef(null);
  const section3Ref = useRef(null);
  const section4Ref = useRef(null);
  const section5Ref = useRef(null);
  const section6Ref = useRef(null);
  const section7Ref = useRef(null);
  useEffect(() => {
    window.scrollTo(0, 1);
  }, []);

  const scrollToSection = (section) => {
    let targetSection = null;

    switch (section) {
      case "menu-development":
        targetSection = section1Ref.current;
        break;
      case "menu-costing":
        targetSection = section2Ref.current;
        break;
      case "chef-menu-development":
        targetSection = section3Ref.current;
        break;
      case "menu-engineering":
        targetSection = section4Ref.current;
        break;
      case "brand-guide":
        targetSection = section5Ref.current;
        break;
      case "logo-development":
        targetSection = section6Ref.current;
        break;
      case "graphic-design":
        targetSection = section7Ref.current;
        break;
      default:
        break;
    }

    if (targetSection) {
      const topOffset = targetSection.offsetTop;
      const offset = 90;
      const scrollOptions = {
        top: topOffset - offset,
        behavior: "smooth",
      };
      // targetSection.scrollIntoView(scrollOptions);
      window.scrollTo(scrollOptions);
    }
  };

  const starter = [
    {
      name: "Delicious Starter",
      price: "$13.00",
    },
    {
      name: "To Share",
      price: "$7.00",
    },
    {
      name: "Best Option",
      price: "$9.00",
    },
    {
      name: "Starter 1",
      price: "$11.00",
    },
    {
      name: "Starter 2",
      price: "$16.00",
    },
  ];

  const burger = [
    {
      name: "Delicious Meat",
      price: "$14.00",
    },
    {
      name: "Best Burger",
      price: "$13.50",
    },
    {
      name: "Authentic Grill",
      price: "$12.00",
    },
    {
      name: "BBQ Meat",
      price: "$14.80",
    },
    {
      name: "Best Taste",
      price: "$13.50",
    },
  ];

  const desert = [
    {
      name: "Brownie",
      price: "$4.50",
    },
    {
      name: "Best Cheesecake",
      price: "$6.00",
    },
    {
      name: "Carrot Cake",
      price: "$5.00",
    },
    {
      name: "Chocolate",
      price: "$3.00",
    },
    {
      name: "Apple Pie",
      price: "$6.00",
    },
  ];

  const drink = [
    {
      name: "Drink",
      price: "$2.00",
    },
    {
      name: "Beer",
      price: "$3.00",
    },
    {
      name: "Cocktail",
      price: "$8.00",
    },
  ];
  // Function to toggle loading state
  const toggleLoading = (status) => {
    setLoading(status);
  };
  return (
    <div>
      {loading && <Loader/>}
      <Navbar scrollToSection={scrollToSection} />
      <div className="video-container-website" ref={section1Ref}>
        <img src={menud1} className="video-background" style={{objectFit:"cover"}}></img>
        <div>
          <div className="start-up-banner  banner-position right container-box     ">
            <div data-aos="fade-left" className="">
              <h1 style={{overflow:"hidden"}}>Incorporate Menu Items that Encourage Repeat Visits</h1>
              <p>
                Using our years of experience, our staff will build menus for
                take-out, dine-in, catering, late-night desires, breakfast
                options, and beverages. We excel at creating menus that strike
                the ideal combination of price, variety, and the undeniable
                "Wow!" factor.
              </p>
              <p>
                With our menu engineering skills, we can develop a menu that
                suits your tastes as well as your budget. We'll balance the
                flavours by combining current dishes with timeless classics so
                that each bite feels just right for you.
              </p>
            </div>
            <div className="">
              <Link to={`${Config.appUrl}/contact`}>
                {" "}
                <a className="btn btn-purple mt-2">
                  Book Consultation <i className="bi bi-arrow-right"></i>
                </a>
              </Link>
            </div>
          </div>
        </div>
      </div>
      <div className=" p-90">
        <div className="container">
          <div className="row g-5 align-items-center">
            <div className="col-lg-6">
              <div className="row g-3">
                <div className="col-6" data-aos="fade-down-right">
                  <img
                    className="img-fluid w-100 h-100 wow zoomIn"
                    src={menud2}
                    alt="About"
                  />
                </div>
                <div className="col-6" data-aos="fade-down-left">
                  <img
                    className="img-fluid w-75 wow zoomIn mt-5 pt-3"
                    src={menud3}
                    alt="About"
                  />
                </div>
                <div className="col-6 text-end" data-aos="fade-up-right">
                  <img
                    className="img-fluid w-75 wow zoomIn"
                    src={menud3}
                    alt="About"
                  />
                </div>
                <div className="col-6 text-end" data-aos="fade-up-left">
                  <img
                    className="img-fluid w-100 h-100 wow zoomIn"
                    src={menud3}
                    alt="About"
                  />
                </div>
              </div>
            </div>
            <div className="col-lg-6 mt-4 p-4">
              <div className="contact-section">
                <h3 ref={section2Ref}>Recipe And Menu Costing</h3>
                <p>
                  We've mastered the art of setting competitive menu prices and
                  influencing clients to invest in your objectives. Let us help
                  you increase your average bill by fine-tuning your menu,
                  improving dishes, and strategically setting prices.
                </p>
                <p>
                  How should I price this dish? Is it bringing in enough profit?
                  How are my ingredient costs affecting my bottom line? Let's
                  boost your profits together!
                </p>
                <h3 ref={section3Ref}>Chef-Crafted Menu Creation</h3>
                <p>
                  We'll take a close look at your menu and compare it with what
                  your competitors offer, keeping in mind your local market and
                  ingredient availability. Our goal is to help you enhance your
                  menu by suggesting updates, removing less popular items, and
                  introducing fresh ideas that align with your business goals.
                  Your success is our priority.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="bg-menu position-relative" ref={section4Ref}>
        <div className="bg-overlay"></div>
        <div className="py-5">
          <div className="row position-relative container-box">
            <div className="col-6 container-box1">
              <div className="accord-section col-12 m-auto">
                <h1>What does menu engineering mean?</h1>
                <p className="text-white">
                  Menu engineering involves analyzing the profitability and
                  popularity of menu items and using this information to decide
                  where they should go on the menu. The aim is straightforward:
                  to enhance the dining experience for each guest while
                  maximizing profits.
                </p>
                <p className="text-white">
                  A business plan serves as a reliable guide for anyone
                  embarking into the food or restaurant industry. As you work on
                  your plan, you may discover new challenges or blind spots,
                  allowing you to lead your business with greater clarity and
                  understanding.
                </p>
                <h1 className="mt-3">
                  Unlocking ways to boost your profits by 15% or even more.
                </h1>
                <p className="text-white">
                  When designing your menu, it's critical to find the right
                  balance between price, variety, and that particular "Wow!"
                  factor. Our menu engineering approach includes working closely
                  with you to guarantee that your menu has a great mix of high
                  and low-cost alternatives, merging current delights with
                  timeless classics that will leave your consumers craving more.
                </p>
                <p className="text-white">
                  In addition, by carefully crafting your menu's design,
                  structure, and product positioning, we assist in shaping
                  client ordering behaviours, resulting in more orders for
                  high-profit meals. Maximizing your menu's sales and
                  profitability requires an accurate six-step process that
                  guarantees you get the most out of each order.
                </p>
                <p className="text-white">
                  At CKC, we got you covered when it comes to menu development.
                  Our team of restaurant professionals not only creates and
                  refines menus, but also delivers recipe creation services to
                  clients throughout Canada and beyond. We collaborate with
                  restaurants to create tasty, on-trend, and cost-effective
                  dishes that provide the foundation for their future success.
                </p>
              </div>
            </div>
            <div className="col-md-6 container-box2">
              <div className="cta-section">
                <h5 className="text-white">Our Menu</h5>
                <h2 className="text-white">Menu Details</h2>
              </div>
              <div className="mt-3">
                <h3 className="text-white">Starters</h3>
                {starter?.map((obj, i) => {
                  return (
                    <div class="menu-content col-10 align-items-center" key={i} style={{paddingTop:"5px"}}>
                      <h6 className="text-white fw-bold mb-0 menu-heading">{obj.name}</h6>
                      <span
                        className="col-7 dotted-line"
                        style={{ border: "1px dashed white", height: "1px" }}
                      ></span>
                      <span className="text-white mb-0 price">{obj.price}</span>
                    </div>
                  );
                })}
              </div>
              <div className="mt-3" style={{paddingTop:"5px"}}>
                <h3 className="text-white">Burgers</h3>
                {burger?.map((obj, i) => {
                  return (
                    <div class="menu-content col-10 align-items-center " key={i}>
                      <h6 className="text-white fw-bold mb-0 menu-heading">{obj.name}</h6>
                      <span
                        className="col-7 dotted-line"
                        style={{ border: "1px dashed white", height: "1px" }}
                      ></span>
                      <span className="text-white mb-0 price">{obj.price}</span>
                    </div>
                  );
                })}
              </div>
              <div className="mt-3" style={{paddingTop:"5px"}}>
                <h3 className="text-white">Desserts</h3>
                {desert?.map((obj, i) => {
                  return (
                    <div class="menu-content col-10 align-items-center" key={i}>
                      <h6 className="text-white fw-bold mb-0  menu-heading">{obj.name}</h6>
                      <span
                        className="col-7 dotted-line"
                        style={{ border: "1px dashed white", height: "1px" }}
                      ></span>
                      <span className="text-white mb-0 price">{obj.price}</span>
                    </div>
                  );
                })}
              </div>
              <div className="mt-3" style={{paddingTop:"5px"}}>
                <h3 className="text-white">Drinks</h3>
                {drink?.map((obj, i) => {
                  return (
                    <div class="menu-content col-10 align-items-center" key={i}>
                      <h6 className="text-white fw-bold mb-0 menu-heading">{obj.name}</h6>
                      <span
                        className="col-7 dotted-line"
                        style={{ border: "1px dashed white", height: "1px" }}
                      ></span>
                      <span className="text-white mb-0 price">{obj.price}</span>
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="d-flex flex-column " style={{paddingTop:"90px",paddingBottom:"86px"}}>
        <div className="brand-logo-graphic" ref={section5Ref}>
          <div className="d-flex main-card p-">
            <div className="card2-left">
              <div className="card2-txt" style={{paddingTop:"10px"}}>
                <h3 className="font-styled">Brand Guide Handbook</h3>
                <p>
                  Your final logo will be merged with all of its elements,
                  colours, spacing, and style requirements, resulting in an
                  unite and distinct brand picture. This is the conclusive Brand
                  Spec Guide, covering everything from your brand name and
                  philosophy to creating your own brand story and identity. It
                  consists of logo design, fonts, colors, and the values that
                  drives your brand.
                </p>
              </div>
              <div className="card2-txt" ref={section6Ref} style={{paddingTop:"10px"}}>
                <h3 className="font-styled">Logo Development</h3>
                <p>
                  Collaborate with our graphic design specialists to bring your
                  logo concepts to reality. Once we've determined on the perfect
                  design, we'll make sure you get the final version in all the
                  right formats, ready to display in your brand guide.
                </p>
              </div>
              <div className="card2-txt" ref={section7Ref} style={{paddingTop:"10px"}}>
                <h3 className="font-styled">Graphic Design</h3>
                <p>
                  Generating a memorable logo and identity, reviving your brand,
                  designing eye-catching packaging, creating engaging
                  infographics, and producing attractive menu brochures, flyers,
                  posters, and large format signs—all designed to engage and
                  connect with your valued customers.
                </p>
              </div>
            </div>
            <div className="card2-right">
              <div className="left1" data-aos="fade-down-right">
                <img src={menud6} alt="Left Image" style={{objectFit:"cover"}}/>
              </div>
              <div className="right1" data-aos="fade-up-left">
                <img src={menud7} alt="Right Image" style={{objectFit:"cover"}}/>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div></div>

      <CkForm toggleLoading={toggleLoading} />
      <Footer />
    </div>
  );
};

export default MenuDevelopment;
